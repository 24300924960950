<template>
  <div>
    <v-dialog max-width="700" persistent v-model="dialog">
      <v-card>
        <v-card-title class="headline grey lighten-3">
          Edit Item Expiration
        </v-card-title>

        <v-card-text class="mt-3">
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" md="6" class="mb-3">
                  <multiselect
                    v-model="selectedUpc"
                    :options="upcList"
                    :custom-label="customLabel"
                    :loading="isLoading"
                    :searchable="true"
                    :internal-search="false"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :options-limit="10"
                    :limit="3"
                    :limit-text="(count) => `and ${count} more`"
                    @search-change="searchUpc"
                    placeholder="Search UPC"
                    label="upc"
                    track-by="upc"
                  >
                    <template slot="option" slot-scope="props">
                      <span
                        >{{ props.option.upc }} -
                        {{ props.option.description }}</span
                      >
                    </template>
                  </multiselect>
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localItem.description"
                    label="Description"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localItem.quantity"
                    label="Quantity"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-select
                    v-model="localItem.aisle"
                    :items="aisles"
                    item-text="name"
                    item-value="name"
                    label="Aisle"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-select
                    v-model="localItem.department_id"
                    :items="departments"
                    item-text="name"
                    item-value="id"
                    label="Department"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-select
                    v-model="localItem.category_id"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    label="Category"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="localItem.expiry_date"
                        label="Expiry Date"
                        readonly
                        outlined
                        dense
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="localItem.expiry_date"
                      @input="menu = false"
                      no-title
                      scrollable
                    />
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localItem.discount_percentage"
                    label="Discount %"
                    outlined
                    dense
                    hide-details
                    :loading="loadingDiscount"
                    readonly
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localItem.current_retail"
                    label="Current Retail"
                    outlined
                    dense
                    hide-details
                    :loading="loadingDiscount"
                    readonly
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localItem.suggested_retail"
                    label="Suggested Retail"
                    outlined
                    dense
                    hide-details
                    :loading="loadingDiscount"
                    readonly
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#8b1a29"
            @click="updateItem(localItem)"
            class="text-white"
            >Save</v-btn
          >
          <v-btn text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  name: "edit-item-dialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      menu: false,
      dialog: true,
      loadingDiscount: false,
      departments: [],
      categories: [],
      aisles: [
        { name: "1A" },
        { name: "1B" },
        { name: "2A" },
        { name: "2B" },
        { name: "3A" },
        { name: "3B" },
        { name: "4A" },
        { name: "4B" },
        { name: "5A" },
        { name: "5B" },
        { name: "6A" },
        { name: "6B" },
        { name: "7A" },
        { name: "7B" },
        { name: "8A" },
        { name: "8B" },
      ],
      localItem: { 
        ...this.item,
        discount_percentage: this.item.discount_percentage || "",
        current_retail: this.item.current_retail || "",
        suggested_retail: this.item.suggested_retail || ""
      },
      upcList: [],
      isLoading: false,
      selectedUpc: null,
    };
  },
  methods: {
    // Helper method to clean UPC string
    cleanUpc(upcString) {
      if (!upcString) return "";
      // Remove all whitespace
      return upcString.replace(/\s+/g, "");
    },
    
    async updateItem(item) {
      let alertData = {};
      
      // Create a clean copy of the item with cleaned UPC
      const itemToSubmit = {...item};
      itemToSubmit.upc = this.cleanUpc(itemToSubmit.upc);
      
      try {
        let res = await this.$axios.put(
          process.env.VUE_APP_API_URL + "/item-expiration/" + item.id,
          itemToSubmit
        );

        if (res.data.status) {
          alertData.alertText = res.data.message;
          alertData.alertType = "success";
          this.$emit("reload-vendors");
        } else {
          alertData.alertText = res.data.message;
          alertData.alertType = "error";
        }

        this.$emit("show-alert", alertData);
        this.$emit("close-dialog");
      } catch (error) {
        alertData.alertText = error.response?.data?.message || "An error occurred";
        alertData.alertType = "error";
        this.$emit("show-alert", alertData);
        this.$emit("close-dialog");
      }
    },
    customLabel({ upc }) {
      return `${upc}`;
    },
    async searchUpc(query) {
      if (!query) return;

      // Clean the query thoroughly
      const cleanedQuery = this.cleanUpc(query);
      if (!cleanedQuery) return;

      this.isLoading = true;
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/get-items-by-upc?upc=${encodeURIComponent(cleanedQuery)}`
        );
        if (response.data.status) {
          this.upcList = response.data.data;
          console.log(this.upcList);
        }
      } catch (error) {
        console.error("Error fetching UPC list:", error);
      } finally {
        this.isLoading = false;
      }
    },
    closeDialog() {
      this.$emit("close-dialog");
      this.dialog = false;
    },
    async getDepartments() {
      let res = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/department"
      );
      if (res.data.status) {
        this.departments = res.data.data;
      }
    },
    async getCategories() {
      let res = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/category"
      );
      if (res.data.status) {
        this.categories = res.data.data;
      }
    },
    async fetchDiscountData() {
      if (!this.localItem.upc || !this.localItem.expiry_date) return;
      
      // Clean UPC thoroughly
      const cleanedUpc = this.cleanUpc(this.localItem.upc);
      if (!cleanedUpc) return;
      
      this.loadingDiscount = true;
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/get-expiry-discount`, {
            params: {
              upc: cleanedUpc,
              expiry_date: this.localItem.expiry_date
            }
          }
        );
        
        if (response.data.status) {
          const discountData = response.data.data;
          this.localItem.discount_percentage = discountData.discount_percentage;
          this.localItem.current_retail = discountData.current_retail;
          this.localItem.suggested_retail = discountData.suggested_retail;
        } else {
          console.error("Failed to fetch discount data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching discount data:", error);
      } finally {
        this.loadingDiscount = false;
      }
    }
  },
  mounted() {
    this.getDepartments();
    this.getCategories();

    if (this.localItem.upc) {
      this.selectedUpc = {
        upc: this.localItem.upc,
        description: this.localItem.description,
        department_id: this.localItem.department_id,
        category_id: this.localItem.category_id,
      };
      
      // If we have both UPC and expiry date, fetch discount data
      if (this.localItem.expiry_date) {
        this.fetchDiscountData();
      }
    }
  },
  watch: {
    selectedUpc(val) {
      if (val) {
        // Clean UPC thoroughly when setting from selection
        this.localItem.upc = val.upc ? this.cleanUpc(val.upc) : "";
        this.localItem.description = val.description;
        this.localItem.department_id = val.department_id;
        this.localItem.category_id = val.category_id;
        
        // If expiry date is already set, fetch discount data
        if (this.localItem.expiry_date) {
          this.fetchDiscountData();
        }
      }
    },
    'localItem.expiry_date': function(newDate) {
      if (newDate && this.localItem.upc) {
        this.fetchDiscountData();
      }
    },
    // Add a watcher to clean UPC whenever it changes
    'localItem.upc': function(newUpc) {
      if (newUpc) {
        // Use setTimeout to avoid infinite loop
        setTimeout(() => {
          const cleanedUpc = this.cleanUpc(newUpc);
          if (cleanedUpc !== newUpc) {
            this.localItem.upc = cleanedUpc;
          }
        }, 0);
      }
    }
  },
};
</script>

<style lang="scss">
.multiselect {
  .multiselect__tags {
    background: white;
    border-radius: 4px;
    border: thin solid rgba(0, 0, 0, 0.38);
    min-height: 40px;
    padding: 4px 12px;
  }

  .multiselect__placeholder {
    color: #8b1a29;
    font-size: 14px;
    margin-bottom: 0;
    padding-top: 3px;
  }

  .multiselect__input {
    font-size: 14px;
  }

  .multiselect__single {
    font-size: 14px;
    padding-top: 2px;
  }

  .multiselect__content-wrapper {
    border-color: rgba(0, 0, 0, 0.12);
  }

  .multiselect__option {
    font-size: 14px;
    min-height: 40px;
    padding: 8px 12px;
  }
}

.v-application ul {
  padding-left: 2px !important;
}
</style>
