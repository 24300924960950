<template>
  <!-- View Dialog Component -->
  <v-dialog max-width="1050" v-model="showViewInventoryDialog" persistent>
    <v-card>
      <v-card-title class="headline grey lighten-3"> View Item </v-card-title>

      <v-tabs v-model="tab" background-color="#8b1a29" dark>
        <v-tab value="1">New Item Detail</v-tab>
        <!-- <v-tab value="2">Update History</v-tab> -->
      </v-tabs>

      <v-card-text class="mt-3">
        <v-tabs-items v-model="tab">
          <!-- Detail Tab -->
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.upc"
                    label="UPC"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>

                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.description"
                    label="Description"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.pack"
                    label="Pack"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.size"
                    label="Size"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>

                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.retail"
                    label="Retail"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.base_unit"
                    label="Base/Unit"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>

                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.net_cost"
                    label="Net Cost"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>

                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="departmentName"
                    label="Dept. Description"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>

                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.upc_case"
                    label="UPC Case"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>

                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.base_case"
                    label="Base/Case"
                    outlined
                    readonly
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleCloseViewDialog()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "view-inventory-dialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    inventoryItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showViewInventoryDialog: this.value,
      historyItems: [],
      tab: "1",
      localInventoryItem: { ...this.inventoryItem },
      historyTableHeaders: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        { text: "Field", value: "field", class: "table-header" },
        { text: "Old Value", value: "old_value", class: "table-header" },
        { text: "New Value", value: "new_value", class: "table-header" },
        { text: "Added At", value: "created_at", class: "table-header" },
      ],
    };
  },
  watch: {
    value(val) {
      this.showViewInventoryDialog = val;
    },
    showViewInventoryDialog(val) {
      this.$emit("input", val);
      if (!val) {
        this.handleCloseViewDialog();
      }
    },
  },
  methods: {
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    handleCloseViewDialog() {
      this.tab = "1";
      this.$emit("close-dialog");
    },
  },
  computed: {
    departmentName() {
      return this.localInventoryItem.department?.name || "-";
    },
    categoryName() {
      return this.localInventoryItem.category?.name || "-";
    },
  },
};
</script>
