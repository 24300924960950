<template>
  <v-container fluid class="pa-0 pa-sm-2">
    <v-data-table
      :headers="getResponsiveHeaders"
      :items="items"
      item-key="id"
      class="elevation-1"
      :loading="loading"
      striped
      :height="$vuetify.breakpoint.smAndDown ? 'calc(100vh - 120px)' : '78vh'"
      fixed-header
      :footer-props="footerProps"
      :mobile-breakpoint="0"
    >
      <template v-slot:top>
        <!-- Desktop Toolbar -->
        <v-toolbar flat v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Enter UPC"
            single-line
            hide-details
            class="search-field"
            @input="getHistory"
            outlined
            dense
          ></v-text-field>
        </v-toolbar>

        <!-- Mobile Toolbar -->
        <v-toolbar flat class="mobile-toolbar" v-else>
          <div class="mobile-actions">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Enter UPC"
              single-line
              hide-details
              class="mobile-search"
              @input="getHistory"
              outlined
              dense
            ></v-text-field>
          </div>
        </v-toolbar>
      </template>

      <!-- Mobile view for items -->
      <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.smAndDown">
        <tr>
          <td>
            <v-card flat class="pa-3 mobile-history-card">
              <div class="mobile-history-row">
                <div class="font-weight-bold">Field:</div>
                <div class="text-capitalize">{{ item.field.replace(/_/g, " ") }}</div>
              </div>
              
              <div class="mobile-history-row">
                <div class="font-weight-bold">Old Value:</div>
                <div>{{ item.old_value ? item.old_value : "-" }}</div>
              </div>
              
              <div class="mobile-history-row">
                <div class="font-weight-bold">New Value:</div>
                <div>{{ item.new_value ? item.new_value : "-" }}</div>
              </div>
              
              <div class="mobile-history-row">
                <div class="font-weight-bold">Added At:</div>
                <div>{{ formattedDate(item.created_at) }}</div>
              </div>
            </v-card>
          </td>
        </tr>
      </template>

      <!-- Desktop view for items -->
      <template v-slot:body="{ items }" v-if="!$vuetify.breakpoint.smAndDown">
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id">
            <td>{{ index + 1 }}</td>
            <td>
              <span class="text-capitalize">{{ item.field.replace(/_/g, " ") }}</span>
            </td>
            <td>
              <span class="text-align-center">{{
                item.old_value ? item.old_value : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center">{{
                item.new_value ? item.new_value : "-"
              }}</span>
            </td>
            <td>
              <span>{{ formattedDate(item.created_at) }}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
  </v-container>
</template>

<script>
import AlertComponent from "@/components/common/AlertComponent";

export default {
  name: "history-listing",
  data() {
    return {
      search: "",
      totalItems: 100,
      loading: false,
      showAlert: false,
      alertData: {},
      itemsPerPage: 10,
      counter: 0,
      headers: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        { text: "Field", value: "field", class: "table-header" },
        { text: "Old Value", value: "old_value", class: "table-header" },
        { text: "New Value", value: "new_value", class: "table-header" },
        { text: "Added At", value: "created_at", class: "table-header" },
      ],
      mobileHeaders: [
        {
          text: "History Details",
          value: "field",
          sortable: false,
          class: "table-header",
        },
      ],
      footerProps: {
        "items-per-page-options": [
          20,
          50,
          100,
          500,
          { text: "All", value: -1 },
        ],
      },
      items: [],
      dialog: false,
    };
  },
  components: {
    AlertComponent
  },
  mounted() {
    document.title = "Razcofoods | Histories";
  },
  computed: {
    getResponsiveHeaders() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return this.mobileHeaders;
      }
      return this.headers;
    }
  },
  methods: {
    async getHistory() {
      try {
        this.loading = true;
        let items = await this.$axios.get(
          process.env.VUE_APP_API_URL+"/update-history",
          {
            params: {
              upc: this.search,
            },
          }
        );

        if (items.data && items.data.status) {
          this.items = items.data.data;
        } else {
          this.items = [];
          this.alertData.alertText = items.data.message;
          this.alertData.alertType = "error";
          this.showAlert = true;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.items = [];
        this.alertData.alertText = error.response?.data?.message || "An error occurred";
        this.alertData.alertType = "error";
        this.showAlert = true;
      }
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
};
</script>

<style>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
}

/* Mobile specific styles */
.search-field {
  max-width: 300px;
}

.mobile-toolbar {
  padding: 8px 8px !important;
  height: auto !important;
}

.mobile-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mobile-search {
  width: 100%;
}

/* Mobile history card styles */
.mobile-history-card {
  border-left: 3px solid #8b1a29;
  margin-bottom: 8px;
}

.mobile-history-row {
  display: flex;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mobile-history-row.field-row {
  background-color: rgba(139, 26, 41, 0.1);
  padding: 8px;
  border-radius: 4px 4px 0 0;
}

.mobile-history-row.date-row {
  border-bottom: none;
  padding-top: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.mobile-history-row div:first-child {
  width: 100px;
  flex-shrink: 0;
}

.mobile-history-row div:last-child {
  word-break: break-word;
}

@media (max-width: 600px) {
  .v-data-table {
    font-size: 14px;
  }

  .v-toolbar {
    padding: 8px;
  }
}
</style>
