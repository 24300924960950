<template>
  <v-container fluid class="pa-0 pa-sm-2">
    <v-data-table
      :headers="getResponsiveHeaders"
      :items="items"
      :search="search"
      item-key="unique_identifier"
      class="elevation-1"
      :loading="loading"
      striped
      :height="$vuetify.breakpoint.smAndDown ? 'calc(100vh - 120px)' : '78vh'"
      fixed-header
      :footer-props="footerProps"
      :mobile-breakpoint="0"
    >
      <template v-slot:top>
        <!-- Desktop Toolbar -->
        <v-toolbar flat v-if="!$vuetify.breakpoint.smAndDown">
          <v-btn
            color="#8b1a29"
            class="ml-2 text-white"
            @click="showAddVendorDialog = true"
          >
            <v-icon left>mdi-plus</v-icon>
            Add Vendor
          </v-btn>

          <v-btn
            color="#8b1a29"
            class="ml-2 text-white"
            @click="openFileDialog"
          >
            <v-icon left>mdi-cloud-upload</v-icon>
            Upload Vendor
          </v-btn>

          <input
            type="file"
            ref="fileInputVendor"
            style="display: none"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="submitFile"
          />

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="search-field"
            outlined
            dense
          ></v-text-field>
        </v-toolbar>

        <!-- Mobile Toolbar -->
        <v-toolbar flat class="mobile-toolbar" v-else>
          <div class="mobile-actions">
            <div class="mobile-top-row">
              <v-btn
                color="#8b1a29"
                class="mobile-btn text-white"
                @click="showAddVendorDialog = true"
              >
                <v-icon left>mdi-plus</v-icon>
                Add
              </v-btn>

              <v-btn
                color="#8b1a29"
                class="ml-2 mobile-btn text-white"
                @click="openFileDialog"
              >
                <v-icon left>mdi-cloud-upload</v-icon>
                Upload
              </v-btn>
            </div>

            <input
              type="file"
              ref="fileInputVendor"
              style="display: none"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              @change="submitFile"
            />

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mobile-search mt-2"
              outlined
              dense
            ></v-text-field>
          </div>
        </v-toolbar>
      </template>

      <!-- Mobile view for items -->
      <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.smAndDown">
        <tr>
          <td>
            <v-card flat class="pa-3 mobile-vendor-card">
              <div class="d-flex justify-space-between align-center">
                <div class="flex-grow-1">
                  <div class="font-weight-bold">{{ item.name || "-" }}</div>
                  <div>ID: {{ item.unique_identifier || "-" }}</div>
                  <div>Phone: {{ item.phone || "-" }}</div>
                  <div class="d-flex">
                    <div class="pr-2">Low Margin: {{ item.low_margin || "-" }}</div>
                    <div>High Margin: {{ item.high_margin || "-" }}</div>
                  </div>
                  <div>Added: {{ formattedDate(item.created_at) }}</div>
                </div>
                <div class="mobile-actions-column">
                  <v-btn icon small @click="viewVendorDetail(item)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn icon small @click="editVendor(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon small @click="deleteVendor(item)" color="red">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </td>
        </tr>
      </template>

      <!-- Desktop view for items -->
      <template v-slot:body="{ items }" v-if="!$vuetify.breakpoint.smAndDown">
        <tbody>
          <tr v-for="(item, index) in items" :key="item.unique_identifier">
            <td>{{ index + 1 }}</td>
            <td>
              <span class="text-align-center">{{ 
                item?.unique_identifier ? item.unique_identifier : "-" 
              }}</span>
            </td>
            <td>
              <span class="text-align-center">{{ item.name ? item.name : "-" }}</span>
            </td>
            <td>
              <span class="text-align-center">{{
                item.phone ? item.phone : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center">{{
                item.low_margin ? item.low_margin : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center">{{
                item.high_margin ? item.high_margin : "-"
              }}</span>
            </td>
            <td>
              <span>{{
                item.created_at ? formattedDate(item.created_at) : "-"
              }}</span>
            </td>
            <td>
              <span>
                <v-icon @click="viewVendorDetail(item)">mdi-eye</v-icon>
              </span>
              <span class="ml-1"
                ><v-icon @click="editVendor(item)">mdi-pencil</v-icon></span
              >
              <span
                ><v-icon class="text-red" @click="deleteVendor(item)"
                  >mdi-delete</v-icon
                ></span
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <add-vendor-dialog
      v-if="showAddVendorDialog"
      @close-dialog="showAddVendorDialog = false"
      @reload-inventory="getVendors"
      @show-alert="showAlertData"
    />

    <view-vendor-dialog
      v-if="showViewVendorDialog"
      v-model="showViewVendorDialog"
      :vendor="selectedVendor"
      @close-dialog="showViewVendorDialog = false"
    />

    <edit-vendor-dialog
      v-if="showEditVendorDialog"
      v-model="showEditVendorDialog"
      :vendor="selectedVendor"
      @close-dialog="showEditVendorDialog = false"
      @reload-vendors="getVendors"
      @show-alert="showAlertData"
    />

    <delete-vendor-dialog
      v-if="showDeleteVendorDialog"
      v-model="showDeleteVendorDialog"
      :vendor="selectedVendor"
      @close-dialog="showDeleteVendorDialog = false"
      @reload-vendors="getVendors"
      @show-alert="showAlertData"
    />

    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
  </v-container>
</template>

<script>
import AddVendorDialog from "@/components/Vendors/AddVendorDialog";
import ViewVendorDialog from "@/components/Vendors/ViewVendorDialog";
import EditVendorDialog from "@/components/Vendors/EditVendorDialog";
import DeleteVendorDialog from "@/components/Vendors/DeleteVendorDialog";
import AlertComponent from "@/components/common/AlertComponent";

export default {
  name: "vendor-listing",
  data() {
    return {
      search: "",
      loading: false,
      itemsPerPage: 10,
      counter: 0,
      showAlert: false,
      alertData: {},
      headers: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        {
          text: "Unique Identifier",
          value: "unique_identifier",
          class: "table-header"
        },
        { text: "Name", value: "name", class: "table-header" },
        { text: "Phone", value: "phone", class: "table-header" },
        {
          text: "Low Margin",
          value: "low_margin",
          class: "table-header",
        },
        {
          text: "High Margin",
          value: "high_margin",
          class: "table-header",
        },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
        },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
          width: "10%",
        },
      ],
      mobileHeaders: [
        {
          text: "Vendor Details",
          value: "name",
          sortable: false,
          class: "table-header",
        },
      ],
      footerProps: {
        "items-per-page-options": [
          20,
          50,
          100,
          500,
          { text: "All", value: -1 },
        ],
      },
      items: [],
      showAddVendorDialog: false,
      showViewVendorDialog: false,
      showEditVendorDialog: false,
      showDeleteVendorDialog: false,
      selectedVendor: {},
      file: null,
    };
  },
  components: {
    AddVendorDialog,
    ViewVendorDialog,
    EditVendorDialog,
    AlertComponent,
    DeleteVendorDialog,
  },
  mounted() {
    document.title = "Razcofoods | Vendors";
    this.getVendors();
  },
  computed: {
    getResponsiveHeaders() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return this.mobileHeaders;
      }
      return this.headers;
    }
  },
  methods: {
    openFileDialog() {
      this.$refs.fileInputVendor.click();
    },

    submitFile(event) {
      const files = event.target.files;
      if (files.length > 0) {
        this.file = files[0];
      }
      if (!this.file) return;
      this.loading = true;
      const formData = new FormData();
      formData.append("file", this.file);

      this.$axios
        .post(process.env.VUE_APP_API_URL + "/vendors/upload", formData)
        .then(() => {
          this.loading = false;
          this.getVendors();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    async getVendors() {
      this.loading = true;
      let items = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/vendors"
      );
      if (items.data && items.data.status) {
        this.items = items.data.data;
      }
      this.loading = false;
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    showAlertData(alertData) {
      this.alertData = alertData;
      this.showAlert = true;
    },
    viewVendorDetail(item) {
      this.selectedVendor = item;
      this.showViewVendorDialog = true;
    },
    editVendor(item) {
      this.selectedVendor = item;
      this.showEditVendorDialog = true;
    },
    deleteVendor(item) {
      this.selectedVendor = item;
      this.showDeleteVendorDialog = true;
    },
  },
};
</script>

<style>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
}

.v-application .primary--text {
  color: #8b1a29 !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #8b1a29 !important;
}

.text-red {
  color: red;
}

.text-white {
  color: white;
}

/* Mobile specific styles */
.search-field {
  max-width: 300px;
}

.mobile-toolbar {
  padding: 32px 8px !important;
  height: auto !important;
}

.mobile-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mobile-top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.mobile-search {
  width: 100%;
}

.mobile-btn {
  height: 36px;
  font-size: 12px;
  white-space: nowrap;
  padding: 0 8px;
}

.mobile-vendor-card {
  border-left: 3px solid #8b1a29;
  margin-bottom: 8px;
}

.mobile-actions-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.mobile-actions-column .v-btn {
  margin: 4px 0;
}

@media (max-width: 600px) {
  .v-data-table {
    font-size: 14px;
  }

  .v-toolbar {
    padding: 8px;
  }
  
  .mobile-actions-column {
    margin-left: 8px;
  }
}
</style>

