var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-app-bar',{attrs:{"app":"","flat":"","color":"white","elevation":"1"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-spacer'),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-avatar',{attrs:{"size":"32"}},[_c('v-img',{attrs:{"src":_vm.customer.image}})],1)],1)]}}],null,false,3065148713)},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1)],1):_vm._e(),_c('v-navigation-drawer',{staticClass:"d-flex flex-column",attrs:{"mini-variant":_vm.miniVariant,"permanent":_vm.$vuetify.breakpoint.mdAndUp,"temporary":_vm.$vuetify.breakpoint.smAndDown,"app":"","width":"250","height":"100%"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"sidebar-container"},[_c('div',{staticClass:"sidebar-header"},[_c('v-list',[_c('v-list-item',[(!_vm.miniVariant)?_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 text-center"},[_c('img',{staticClass:"logo-img",attrs:{"src":require("@/assets/razco-logo.jpeg")}})])],1):_vm._e()],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{staticClass:"toggle-btn",class:{ 'toggle-btn-mini': _vm.miniVariant },attrs:{"icon":""},on:{"click":function($event){_vm.miniVariant = !_vm.miniVariant}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.miniVariant ? "mdi-chevron-right" : "mdi-chevron-left"))])],1):_vm._e()],1),_c('div',{staticClass:"sidebar-content"},[_c('v-list',{attrs:{"dense":"","nav":""}},_vm._l((_vm.pages),function(page,index){return _c('v-list-item',{key:index,class:{
              'v-list-item--active': _vm.activeItem === page.value,
              'sidebar-item': true,
              'mini-sidebar': _vm.miniVariant,
            },attrs:{"link":""},on:{"click":function($event){return _vm.handlePageClick(page)}}},[_c('v-list-item-icon',{class:{ 'icon-centered': _vm.miniVariant }},[_c('v-icon',{attrs:{"size":"24"}},[_vm._v(_vm._s(page.icon))])],1),(!_vm.miniVariant)?_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"sidebar-text"},[_vm._v(_vm._s(page.name))])],1):_vm._e()],1)}),1)],1),_c('div',{staticClass:"sidebar-footer"},[_c('v-divider'),_c('v-list',[_c('v-list-item',{staticClass:"profile-item pa-0"},[_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-reverse-transition","bottom":"","left":!_vm.miniVariant},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({class:[
                    'profile-wrapper',
                    { 'profile-mini': _vm.miniVariant },
                  ]},'div',attrs,false),on),[_c('v-list-item-avatar',{attrs:{"size":"40"}},[_c('v-img',{attrs:{"src":_vm.customer.image}})],1),(!_vm.miniVariant)?_c('v-list-item-content',{staticClass:"profile-content"},[_c('v-list-item-title',{staticClass:"profile-name"},[_vm._v(_vm._s(_vm.customer.name))])],1):_vm._e()],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1)],1)],1)],1)])]),_c('v-main',{staticClass:"set-background"},[_c(_vm.currentComponent,{tag:"component"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }