<template>
    <v-dialog v-model="dialog" max-width="700px" persistent>
      <v-card>
        <v-card-title class="headline">
          <span>Select Columns to Display</span>
        </v-card-title>
        
        <v-card-text class="pt-4 pb-0">
          <!-- Responsive layout for action buttons and search -->
          <div class="d-flex flex-column flex-sm-row mb-3 px-2">
            <div class="d-flex mb-2 mb-sm-0">
              <v-btn small color="#8b1a29" class="text-white mr-2" @click="selectAll">
                <v-icon left small>mdi-checkbox-marked-outline</v-icon>
                Select All
              </v-btn>
              <v-btn small outlined color="#8b1a29" @click="deselectAll">
                <v-icon left small>mdi-checkbox-blank-outline</v-icon>
                Deselect All
              </v-btn>
            </div>
            
            <v-spacer class="d-none d-sm-block"></v-spacer>
            
            <!-- Search field with full width on mobile -->
            <v-text-field
              v-model="searchColumn"
              append-icon="mdi-magnify"
              label="Search columns"
              single-line
              hide-details
              dense
              outlined
              class="column-search mt-2 mt-sm-0"
            ></v-text-field>
          </div>
          
          <v-divider></v-divider>
          
          <v-container fluid class="column-container pa-2">
            <v-row dense>
              <v-col 
                cols="12" 
                sm="6" 
                md="4" 
                v-for="(header, index) in filteredHeaders" 
                :key="index"
                class="py-1"
              >
                <v-checkbox
                  v-model="selectedColumns"
                  :value="header.value"
                  :label="header.text"
                  color="#8b1a29"
                  hide-details
                  dense
                  class="column-checkbox"
                  :disabled="header.value === 'number' || header.value === 'action'"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        
        <v-divider></v-divider>
        
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text color="grey darken-1" @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn color="#8b1a29" class="text-white" @click="saveColumnPreferences">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: "column-selection-dialog",
    props: {
      value: Boolean,
      headers: Array,
      userSelectedColumns: Array
    },
    data() {
      return {
        selectedColumns: [],
        availableHeaders: [],
        searchColumn: ""
      };
    },
    computed: {
      dialog: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        }
      },
      filteredHeaders() {
        if (!this.searchColumn) {
          return this.availableHeaders;
        }
        
        const searchTerm = this.searchColumn.toLowerCase();
        return this.availableHeaders.filter(header => 
          header.text.toLowerCase().includes(searchTerm)
        );
      }
    },
    watch: {
      value(newVal) {
        if (newVal) {
          this.initializeData();
        }
      }
    },
    methods: {
      initializeData() {
        this.availableHeaders = [...this.headers];
        this.searchColumn = "";
        
        // Initialize selected columns from user preferences or defaults
        if (this.userSelectedColumns && this.userSelectedColumns.length > 0) {
          this.selectedColumns = [...this.userSelectedColumns];
        } else {
          // Default to all columns
          this.selectedColumns = this.availableHeaders
            .map(header => header.value);
        }
        
        // Always include number and action columns
        if (!this.selectedColumns.includes('number')) {
          this.selectedColumns.push('number');
        }
        if (!this.selectedColumns.includes('action')) {
          this.selectedColumns.push('action');
        }
      },
      selectAll() {
        this.selectedColumns = this.availableHeaders
          .map(header => header.value);
      },
      deselectAll() {
        // Keep only number and action columns
        this.selectedColumns = ['number', 'action'];
      },
      closeDialog() {
        this.$emit("input", false);
      },
      saveColumnPreferences() {
        this.$emit("save-columns", this.selectedColumns);
        this.closeDialog();
      }
    }
  };
  </script>
  
  <style scoped>
  .v-card__title {
    word-break: normal;
    padding: 16px 20px;
    font-size: 18px;
  }
  
  .column-container {
    max-height: 400px;
    overflow-y: auto;
  }
  
  .column-checkbox :deep(.v-label) {
    font-size: 14px;
  }
  
  /* Make search field responsive */
  .column-search {
    width: 100%;
    max-width: 100%;
  }
  
  @media (min-width: 600px) {
    .column-search {
      max-width: 200px;
    }
  }
  
  /* Custom scrollbar for the columns container */
  .column-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .column-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .column-container::-webkit-scrollbar-thumb {
    background: #8b1a29;
    border-radius: 4px;
  }
  
  .column-container::-webkit-scrollbar-thumb:hover {
    background: #bb0d24;
  }
  
  /* Make checkboxes more compact */
  .column-checkbox :deep(.v-input__slot) {
    margin-bottom: 0;
  }
  
  .column-checkbox {
    margin-top: 0;
    padding-top: 0;
  }
  </style>
  