<template>
  <v-container fluid class="pa-0 pa-sm-2">
    <v-data-table
      :headers="getResponsiveHeaders"
      :items="items"
      :search="search"
      item-key="id"
      class="elevation-1"
      :loading="loading"
      striped
      :height="$vuetify.breakpoint.smAndDown ? 'calc(100vh - 120px)' : '78vh'"
      fixed-header
      :footer-props="footerProps"
      :mobile-breakpoint="0"
    >
      <template v-slot:top>
        <!-- Desktop Toolbar -->
        <v-toolbar flat v-if="!$vuetify.breakpoint.smAndDown">
          <v-btn
            color="#8b1a29"
            class="ml-2 text-white"
            @click="showAddCategoryDialog = true"
          >
            <v-icon left>mdi-plus</v-icon>
            Add Category
          </v-btn>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="search-field"
            outlined
            dense
          ></v-text-field>
        </v-toolbar>

        <!-- Mobile Toolbar -->
        <v-toolbar flat class="mobile-toolbar" v-else>
          <div class="mobile-actions">
            <v-btn
              color="#8b1a29"
              class="mobile-btn text-white full-width-btn mb-2"
              @click="showAddCategoryDialog = true"
            >
              <v-icon left>mdi-plus</v-icon>
              Add Category
            </v-btn>

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mobile-search"
              outlined
              dense
            ></v-text-field>
          </div>
        </v-toolbar>
      </template>

      <!-- Mobile view for items -->
      <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.smAndDown">
        <tr>
          <td>
            <v-card flat class="pa-3 mobile-category-card">
              <div class="d-flex justify-space-between align-center">
                <div class="flex-grow-1">
                  <div class="font-weight-bold">{{ item.name || "-" }}</div>
                  <div>Added: {{ formattedDate(item.created_at) }}</div>
                </div>
                <div class="mobile-actions-column">
                  <v-btn icon small @click="viewCategoryDetail(item)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn icon small @click="editCategory(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon small @click="deleteCategory(item)" color="red">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </td>
        </tr>
      </template>

      <!-- Desktop view for items -->
      <template v-slot:body="{ items }" v-if="!$vuetify.breakpoint.smAndDown">
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id">
            <td>{{ index + 1 }}</td>
            <td class="">{{ item.name ? item.name : "-" }}</td>
            <td>
              {{ item.created_at ? formattedDate(item.created_at) : "-" }}
            </td>
            <td>
              <span>
                <v-icon @click="viewCategoryDetail(item)">mdi-eye</v-icon>
              </span>
              <span class="ml-1">
                <v-icon @click="editCategory(item)">mdi-pencil</v-icon>
              </span>
              <span>
                <v-icon class="text-red" @click="deleteCategory(item)"
                  >mdi-delete</v-icon
                >
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <add-category-dialog
      v-if="showAddCategoryDialog"
      @close-dialog="showAddCategoryDialog = false"
      @reload-category="getCategory"
      @show-alert="showAlertData"
    />

    <view-category-dialog
      v-if="showViewCategoryDialog"
      v-model="showViewCategoryDialog"
      :category="selectedCategory"
      @close-dialog="showViewCategoryDialog = false"
    />

    <edit-category-dialog
      v-if="showEditCategoryDialog"
      v-model="showEditCategoryDialog"
      :category="selectedCategory"
      @close-dialog="showEditCategoryDialog = false"
      @reload-category="getCategory"
      @show-alert="showAlertData"
    />

    <delete-category-dialog
      v-if="showDeleteCategoryDialog"
      v-model="showDeleteCategoryDialog"
      :category="selectedCategory"
      @close-dialog="showDeleteCategoryDialog = false"
      @reload-category="getCategory"
      @show-alert="showAlertData"
    />

    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
  </v-container>
</template>

<script>
import AddCategoryDialog from "@/components/Category/AddCategoryDialog";
import ViewCategoryDialog from "@/components/Category/ViewCategoryDialog";
import EditCategoryDialog from "@/components/Category/EditCategoryDialog";
import DeleteCategoryDialog from "@/components/Category/DeleteCategoryDialog";
import AlertComponent from "@/components/common/AlertComponent";

export default {
  name: "category-listing",
  data() {
    return {
      search: "",
      loading: false,
      itemsPerPage: 10,
      counter: 0,
      showAlert: false,
      alertData: {},
      headers: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        { text: "Name", value: "name", class: "table-header" },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
        },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
        },
      ],
      mobileHeaders: [
        {
          text: "Category Details",
          value: "name",
          sortable: false,
          class: "table-header",
        },
      ],
      footerProps: {
        "items-per-page-options": [
          20,
          50,
          100,
          500,
          { text: "All", value: -1 },
        ],
      },
      items: [],
      showAddCategoryDialog: false,
      showViewCategoryDialog: false,
      showEditCategoryDialog: false,
      showDeleteCategoryDialog: false,
      selectedCategory: {},
    };
  },
  components: {
    AddCategoryDialog,
    ViewCategoryDialog,
    EditCategoryDialog,
    AlertComponent,
    DeleteCategoryDialog,
  },
  mounted() {
    document.title = "Razcofoods | Categories";
    this.getCategory();
  },
  computed: {
    getResponsiveHeaders() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return this.mobileHeaders;
      }
      return this.headers;
    },
  },
  methods: {
    async getCategory() {
      this.loading = true;
      let items = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/category"
      );
      if (items.data && items.data.status) {
        this.items = items.data.data;
      }
      this.loading = false;
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    showAlertData(alertData) {
      this.alertData = alertData;
      this.showAlert = true;
    },
    viewCategoryDetail(item) {
      this.selectedCategory = item;
      this.showViewCategoryDialog = true;
    },
    editCategory(item) {
      this.selectedCategory = item;
      this.showEditCategoryDialog = true;
    },
    deleteCategory(item) {
      this.selectedCategory = item;
      this.showDeleteCategoryDialog = true;
    },
  },
};
</script>

<style scoped>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
}

.v-application .primary--text {
  color: #8b1a29 !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #8b1a29 !important;
}

.text-red {
  color: red;
}

.text-white {
  color: white;
}

/* Mobile specific styles */
.search-field {
  max-width: 300px;
}

.mobile-toolbar {
  padding: 32px 8px !important;
  height: auto !important;
}

.mobile-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mobile-top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.mobile-search {
  width: 100%;
}

.mobile-btn {
  height: 36px;
  font-size: 12px;
  white-space: nowrap;
  padding: 0 8px;
}

.mobile-category-card {
  margin-bottom: 8px;
}

.mobile-actions-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.mobile-actions-column .v-btn {
  margin: 4px 0;
}

@media (max-width: 600px) {
  .v-data-table {
    font-size: 14px;
  }

  .v-toolbar {
    padding: 8px;
  }

  .mobile-actions-column {
    margin-left: 8px;
  }
}

/* Full width button on mobile */
.full-width-btn {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/* Optional: Increase button height for better touch target */
.mobile-toolbar .full-width-btn {
  height: 44px !important;
}

/* Make sure the container doesn't restrict button width */
.mobile-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
</style>
