<template>
  <v-container fluid class="pa-0 pa-sm-2">
    <v-data-table
      :headers="getResponsiveHeaders"
      :items="items"
      :search="search"
      item-key="upc"
      class="elevation-1"
      :loading="loading"
      striped
      :height="$vuetify.breakpoint.smAndDown ? 'calc(100vh - 150px)' : '78vh'"
      fixed-header
      :footer-props="footerProps"
      theme="dark"
      :mobile-breakpoint="0"
    >
      <template v-slot:top>
        <!-- Desktop Toolbar -->
        <v-toolbar flat v-if="!$vuetify.breakpoint.smAndDown">
          <v-select
            v-model="selectedVendor"
            :items="vendors"
            item-text="name"
            item-value="id"
            label="Select Vendor"
            outlined
            dense
            @change="getInvoices"
          >
            <template #prepend-item>
              <v-text-field
                v-model="vendorSearch"
                label="Search Vendor"
                dense
                outlined
                class="px-4"
                @input="getVendors"
              ></v-text-field>
            </template>
          </v-select>

          <v-spacer></v-spacer>

          <v-btn
            @click="showAddInvoiceDialog = true"
            class="button"
            :disabled="selectedVendor == null"
          >
            <v-icon left>mdi-plus</v-icon>
            Add Invoice
          </v-btn>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="search-field"
            outlined
            dense
          ></v-text-field>
        </v-toolbar>

        <!-- Mobile Toolbar - Modified layout -->
        <v-toolbar flat class="mobile-toolbar" v-else>
          <div class="mobile-actions">
            <!-- First row: Vendor dropdown and Add Invoice button side by side -->
            <div class="d-flex align-center mb-2">
              <v-select
                v-model="selectedVendor"
                :items="vendors"
                item-text="name"
                item-value="id"
                label="Select Vendor"
                outlined
                dense
                @change="getInvoices"
                class="mobile-select mr-2"
              >
                <template #prepend-item>
                  <v-text-field
                    v-model="vendorSearch"
                    label="Search Vendor"
                    dense
                    outlined
                    class="px-4"
                    @input="getVendors"
                  ></v-text-field>
                </template>
              </v-select>

              <v-btn
                @click="selectedVendor ? (showAddInvoiceDialog = true) : null"
                class="mobile-btn"
                :class="{ 'button-disabled': !selectedVendor }"
                color="#8b1a29"
                dark
              >
                <v-icon left size="small">mdi-plus</v-icon>
                Add Invoice
              </v-btn>
            </div>

            <!-- Second row: Search field -->
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mobile-search"
              outlined
              dense
            ></v-text-field>
          </div>
        </v-toolbar>
      </template>

      <!-- Mobile view for items -->
      <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.smAndDown">
        <tr>
          <td>
            <v-card flat class="pa-3 mobile-invoice-card">
              <div class="d-flex justify-space-between">
                <div class="flex-grow-1">
                  <div class="font-weight-bold">{{ item.name || "-" }}</div>
                  <div>Ref #: {{ item.reference || "-" }}</div>
                  <div>Invoice Date: {{ formattedDate(item.date) }}</div>
                  <div>Added: {{ formattedDate(item.created_at) }}</div>
                </div>
                <div class="mobile-actions-buttons">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon small v-on="on" @click="previewInvoice(item)">
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Preview</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        small
                        v-on="on"
                        @click="viewInvoiceDetail(item)"
                      >
                        <v-icon>mdi-arrow-top-right</v-icon>
                      </v-btn>
                    </template>
                    <span>OCR</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        small
                        v-on="on"
                        @click="downloadInvoice(item)"
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        small
                        class="text-red"
                        v-on="on"
                        @click="deleteInvoice(item)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </div>
              </div>
            </v-card>
          </td>
        </tr>
      </template>

      <!-- Desktop view for items -->
      <template v-slot:body="{ items }" v-if="!$vuetify.breakpoint.smAndDown">
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id">
            <td>{{ index + 1 }}</td>
            <td>
              <span class="text-align-center">{{ item.name || "-" }}</span>
            </td>
            <td>
              <span class="text-align-center">{{ item.reference || "-" }}</span>
            </td>
            <td>
              <span>{{ formattedDate(item.date) }}</span>
            </td>
            <td>
              <span>{{ formattedDate(item.created_at) }}</span>
            </td>
            <td>
              <span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="previewInvoice(item)"
                      >mdi-eye</v-icon
                    >
                  </template>
                  <span>Preview</span>
                </v-tooltip>
              </span>
              <span class="ml-1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="viewInvoiceDetail(item)"
                      >mdi-arrow-top-right</v-icon
                    >
                  </template>
                  <span>OCR</span>
                </v-tooltip>
              </span>
              <span class="ml-1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="downloadInvoice(item)"
                      >mdi-download</v-icon
                    >
                  </template>
                  <span>Download</span>
                </v-tooltip>
              </span>
              <span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="text-red"
                      v-on="on"
                      @click="deleteInvoice(item)"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <add-invoice-dialog
      v-if="showAddInvoiceDialog"
      @close-dialog="showAddInvoiceDialog = false"
      @reload-invoices="getInvoices"
      @show-alert="showAlertData"
      :vendor_id="selectedVendor"
    />

    <delete-invoice-dialog
      v-if="showDeleteInvoiceDialog"
      v-model="showDeleteInvoiceDialog"
      :invoiceItem="selectedInvoice"
      @close-dialog="showDeleteInvoiceDialog = false"
      @reload-invoices="getInvoices"
      @show-alert="showAlertData"
    />

    <preview-invoice-dialog
      v-if="showPreviewInvoiceDialog"
      v-model="showPreviewInvoiceDialog"
      :src="selectedInvoice.image_path"
      @close-dialog="showPreviewInvoiceDialog = false"
    />

    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
  </v-container>
</template>

<script>
import AddInvoiceDialog from "@/components/Invoices/AddInvoiceDialog";
import DeleteInvoiceDialog from "@/components/Invoices/DeleteInvoiceDialog";
import PreviewInvoiceDialog from "@/components/Invoices/PreviewInvoiceDialog";
import AlertComponent from "@/components/common/AlertComponent";

export default {
  name: "invoice-listing",
  data() {
    return {
      vendorSearch: "",
      search: "",
      loading: false,
      itemsPerPage: 10,
      counter: 0,
      showAlert: false,
      alertData: {},
      headers: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        {
          text: "Name",
          value: "name",
          class: "table-header",
        },
        {
          text: "Reference Number",
          value: "reference",
          class: "table-header",
        },
        {
          text: "Invoice Date",
          value: "date",
          class: "table-header",
        },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
        },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
          width: "14%",
        },
      ],
      mobileHeaders: [
        {
          text: "Invoice Details",
          value: "name",
          sortable: false,
          class: "table-header",
        },
      ],
      csvHeaders: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        {
          text: "UPC",
          value: "upc",
          class: "table-header",
        },
        {
          text: "Description",
          value: "description",
          class: "table-header",
          width: "15%",
        },
        {
          text: "Delivery Date",
          value: "delivery_date",
          class: "table-header",
          width: "9%",
        },
        {
          text: "Size",
          value: "size",
          class: "table-header",
          width: "5%",
        },
        {
          text: "Pack",
          value: "pack",
          class: "table-header",
        },
        {
          text: "Quantity",
          value: "quantity",
          class: "table-header",
        },
        {
          text: "Quantity Received",
          value: "quantity_received",
          class: "table-header",
        },
        {
          text: "Case Price",
          value: "case_price",
          class: "table-header",
        },
        {
          text: "Extended Case Price",
          value: "extended_case_price",
          class: "table-header",
        },
        {
          text: "RET Price",
          value: "ret_price",
          class: "table-header",
        },
        {
          text: "RET Profit",
          value: "ret_profit",
          class: "table-header",
        },
        {
          text: "RET GP",
          value: "ret_gp",
          class: "table-header",
        },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
          width: "8%",
        },
      ],

      footerProps: {
        "items-per-page-options": [
          20,
          50,
          100,
          500,
          { text: "All", value: -1 },
        ],
      },
      items: [],
      csvItems: [],
      showAddInvoiceDialog: false,
      showDeleteInvoiceDialog: false,
      showPreviewInvoiceDialog: false,
      selectedInvoice: {},
      vendors: [],
      selectedVendor: null,
      file: null,
    };
  },
  components: {
    AddInvoiceDialog,
    AlertComponent,
    DeleteInvoiceDialog,
    PreviewInvoiceDialog,
  },
  mounted() {
    document.title = "Razcofoods | Invoices";
    this.getVendors();
  },
  computed: {
    getResponsiveHeaders() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return this.mobileHeaders;
      }
      return this.headers;
    },
  },
  methods: {
    async getInvoiceDetail(invoiceId) {
      this.csvItems = [];
      this.loading = true;
      await this.$axios
        .get(process.env.VUE_APP_API_URL + "/invoice/data/" + invoiceId)
        .then((res) => {
          if (res.data && res.data.status) {
            this.csvItems = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;
    },
    async downloadInvoice(item) {
      console.log(item);
      await this.getInvoiceDetail(item.id);
      const csvContent = this.generateCSV();
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "invoice_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    generateCSV() {
      const header = this.csvHeaders
        .filter((h) => h.value !== "action")
        .map((h) => h.text)
        .join(",");

      const rows = this.csvItems.map((item) =>
        this.csvHeaders
          .filter((h) => h.value !== "action")
          .map((h) => this.formatCSVField(item[h.value]))
          .join(",")
      );

      return [header, ...rows].join("\n");
    },

    formatCSVField(value) {
      if (value === null || value === undefined) {
        return "";
      }
      const escapedValue = String(value).replace(/"/g, '""');
      return `"${escapedValue}"`;
    },

    openFileDialog() {
      this.$refs.fileInput.click();
    },

    submitFile(event) {
      const files = event.target.files;
      if (files.length > 0) {
        this.file = files[0];
      }
      if (!this.file) return;
      this.loading = true;
      const formData = new FormData();
      formData.append("vendor_id", this.selectedVendor);
      formData.append("image", this.file);

      this.$axios
        .post(process.env.VUE_APP_API_URL + "/invoices", formData)
        .then(() => {
          this.loading = false;
          this.getInvoices();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    async getInvoices() {
      this.items = [];
      this.loading = true;
      await this.$axios
        .get(process.env.VUE_APP_API_URL + "/invoices/" + this.selectedVendor)
        .then((res) => {
          if (res.data && res.data.status) {
            this.items = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;
    },
    async getVendors() {
      this.loading = true;
      this.$axios
        .get(
          process.env.VUE_APP_API_URL + "/vendors?search=" + this.vendorSearch
        )
        .then((res) => {
          if (res.data && res.data.status) {
            this.vendors = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    showAlertData(alertData) {
      this.alertData = alertData;
      this.showAlert = true;
    },
    previewInvoice(item) {
      this.selectedInvoice = item;
      this.showPreviewInvoiceDialog = true;
    },
    viewInvoiceDetail(item) {
      const url = "/invoice/" + item.id;
      window.open(url, "_blank");
    },
    deleteInvoice(item) {
      this.selectedInvoice = item;
      this.showDeleteInvoiceDialog = true;
    },
    compareInvoiceDetail(item) {
      const url = "/invoice/comparison/" + item.id;
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
}

.v-application .primary--text {
  color: #8b1a29 !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #8b1a29 !important;
}

.text-red {
  color: red;
}

.text-white {
  color: white;
}

.v-select {
  margin-top: 24px;
  width: 50%;
  max-width: 20%;
}

.button {
  margin-right: 20px;
  background-color: #8b1a29 !important;
  color: #fff;
  float: right;
}

/* Updated Mobile specific styles */
.mobile-toolbar {
  padding: 50px 8px !important;
  height: auto !important;
}

.mobile-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mobile-search {
  width: 100%;
  margin-bottom: 25px;
}

.mobile-select {
  margin-top: 26px !important;
  max-width: 70% !important;
}

.mobile-btn {
  height: 36px;
  font-size: 12px;
  white-space: nowrap;
  background-color: #8b1a29 !important;
  min-width: 90px !important;
}

@media (max-width: 600px) {
  .v-data-table {
    font-size: 14px;
  }

  .v-toolbar {
    padding: 8px;
  }

  .mobile-actions-buttons {
    margin-left: 8px;
  }

  .v-select {
    margin-top: 0;
  }
}

/* Make disabled button visible on mobile */
.mobile-btn.v-btn--disabled {
  opacity: 0.7 !important;
  background-color: #8b1a29 !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

.mobile-btn.v-btn--disabled .v-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}

/* Custom disabled button style that remains visible */
.button-disabled {
  opacity: 0.6 !important;
  cursor: not-allowed !important;
  box-shadow: none !important;
  position: relative;
}

.button-disabled:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.2);
  pointer-events: none;
}
</style>
