<template>
  <v-app>
    <v-app-bar
      app
      flat
      color="white"
      v-if="$vuetify.breakpoint.smAndDown"
      elevation="1"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <!-- <v-toolbar-title class="text-center">
        <img src="@/assets/razco-logo.jpeg" height="25" />
      </v-toolbar-title> -->
      <v-spacer></v-spacer>
      <v-menu offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-avatar size="32">
              <v-img :src="customer.image"></v-img>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :temporary="$vuetify.breakpoint.smAndDown"
      app
      width="250"
      height="100%"
      class="d-flex flex-column"
    >
      <div class="sidebar-container">
        <!-- Top Logo Section -->
        <div class="sidebar-header">
          <v-list>
            <v-list-item>
              <v-list-item-content v-if="!miniVariant">
                <v-list-item-title class="text-h6 text-center">
                  <img src="@/assets/razco-logo.jpeg" class="logo-img" />
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            icon
            class="toggle-btn"
            :class="{ 'toggle-btn-mini': miniVariant }"
            @click="miniVariant = !miniVariant"
          >
            <v-icon size="20">{{
              miniVariant ? "mdi-chevron-right" : "mdi-chevron-left"
            }}</v-icon>
          </v-btn>
        </div>

        <!-- Scrollable Navigation Menu -->
        <div class="sidebar-content">
          <v-list dense nav>
            <v-list-item
              v-for="(page, index) in pages"
              :key="index"
              link
              :class="{
                'v-list-item--active': activeItem === page.value,
                'sidebar-item': true,
                'mini-sidebar': miniVariant,
              }"
              @click="handlePageClick(page)"
            >
              <v-list-item-icon :class="{ 'icon-centered': miniVariant }">
                <v-icon size="24">{{ page.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content v-if="!miniVariant">
                <v-list-item-title class="sidebar-text">{{
                  page.name
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>

        <!-- Profile Section -->
        <div class="sidebar-footer">
          <v-divider></v-divider>
          <v-list>
            <v-list-item class="profile-item pa-0">
              <v-menu
                offset-y
                transition="slide-y-reverse-transition"
                bottom
                :left="!miniVariant"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    :class="[
                      'profile-wrapper',
                      { 'profile-mini': miniVariant },
                    ]"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-avatar size="40">
                      <v-img :src="customer.image"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content
                      v-if="!miniVariant"
                      class="profile-content"
                    >
                      <v-list-item-title class="profile-name">{{
                        customer.name
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </div>
                </template>
                <v-list>
                  <v-list-item @click="logout">
                    <v-list-item-title>Logout</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-navigation-drawer>

    <v-main class="set-background">
      <component :is="currentComponent"></component>
    </v-main>
  </v-app>
</template>

<script>
import ListingData from "@/components/Inventory/ListingData";
import HistoryListing from "@/components/History/HistoryListing";
import VendorListing from "@/components/Vendors/VendorListing";
import UserListing from "@/components/Users/UserListing";
import InvoiceListing from "@/components/Invoices/InvoiceListing";
import InvoiceDetailListing from "@/components/Invoices/InvoiceDetail/InvoiceDetailListing";
import InvoiceComparison from "@/components/Invoices/InvoiceComparison";
import PriceChangeListing from "@/components/PriceChange/PriceChangeListing";
import CategoryListing from "@/components/Category/CategoryListing";
import DepartmentListing from "@/components/Department/DepartmentListing";
import ItemExpirationListing from "../ItemExpiration/ItemExpirationListing";
import NewItemListing from "../NewItems/NewItemListing";
import router from "@/router";

export default {
  data() {
    return {
      drawer: true,
      miniVariant: false,
      activeItem: router.currentRoute.name,
      pages: [
        { name: "Items Management", icon: "mdi-bank", value: "items" },
        { name: "New Items", icon: "mdi-new-box", value: "new_items" },
        {
          name: "Items Expiration",
          icon: "mdi-clock-alert-outline",
          value: "item_expiration",
        },
        { name: "Trackability", icon: "mdi-history", value: "histories" },
        { name: "Vendors", icon: "mdi-account-multiple", value: "vendors" },
        { name: "Invoices", icon: "mdi-note-text", value: "invoices" },
        { name: "Price Change", icon: "mdi-cash", value: "price_change" },
        { name: "Categories", icon: "mdi-view-grid", value: "categories" },
        {
          name: "Departments",
          icon: "mdi-office-building",
          value: "departments",
        },
        { name: "Users", icon: "mdi-account-outline", value: "users" },
      ],
      customer: {
        image: "https://randomuser.me/api/portraits/men/85.jpg",
        name: JSON.parse(localStorage.getItem("grocer_user"))?.name,
      },
    };
  },
  methods: {
    handlePageClick(page) {
      this.activeItem = page.value;
      this.$router.push("/" + page.value);
      if (this.$vuetify.breakpoint.smAndDown) {
        this.drawer = false;
      }
    },
    logout() {
      localStorage.removeItem("grocer_user");
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
  },
  computed: {
    currentComponent() {
      const componentsMap = {
        items: "ListingData",
        new_items: "NewItemListing",
        item_expiration: "ItemExpirationListing",
        histories: "HistoryListing",
        vendors: "VendorListing",
        invoices: "InvoiceListing",
        users: "UserListing",
        invoice_detail_listing: "InvoiceDetailListing",
        invoice_comparison: "InvoiceComparison",
        price_change: "PriceChangeListing",
        categories: "CategoryListing",
        departments: "DepartmentListing",
      };
      return componentsMap[this.activeItem];
    },
  },
  components: {
    ListingData,
    HistoryListing,
    VendorListing,
    UserListing,
    InvoiceListing,
    InvoiceDetailListing,
    InvoiceComparison,
    PriceChangeListing,
    CategoryListing,
    DepartmentListing,
    ItemExpirationListing,
    NewItemListing,
  },
  created() {
    this.drawer = this.$vuetify.breakpoint.mdAndUp;
  },
};
</script>

<style scoped>
.sidebar-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
}

.sidebar-header {
  position: sticky;
  top: 0;
  z-index: 2;
  background: white;
}

.sidebar-content {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 60px; /* Space for footer */
}

.sidebar-footer {
  position: fixed;
  bottom: 0;
  width: inherit;
  background: white;
  z-index: 2;
}

.profile-section {
  position: static;
  width: 100%;
  background: white;
}

.profile-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  cursor: pointer;
}

.profile-mini {
  justify-content: center;
  padding: 16px 6px;
}

.profile-content {
  margin-left: 12px;
}

.profile-name {
  font-size: 14px;
  font-weight: 500;
}

.toggle-btn {
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 999;
  background: white !important;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 28px !important;
  width: 28px !important;
}

.toggle-btn-mini {
  right: 12px;
}

/* Ensure icon is centered in button */
.toggle-btn .v-icon {
  margin: 0;
  padding: 0;
}

.icon-centered {
  margin: 0 auto;
  justify-content: center;
}

.mini-sidebar {
  justify-content: center;
  padding: 8px 0;
}

.sidebar-text {
  font-size: 16px !important;
  font-weight: 500;
}

.logo-img {
  max-height: 100px;
  width: auto;
}

.v-list-item--active {
  background-color: #8b1a29;
  color: #fff;
}

.set-background {
  background-color: #fdf5f5 !important;
}
</style>
