<template>
  <v-container fluid class="pa-0 pa-sm-2">
    <v-data-table
      v-if="activeTable === 'new'"
      :headers="getResponsiveHeaders"
      :items="items"
      :search="search"
      item-key="upc"
      class="elevation-1"
      :loading="loading"
      striped
      :height="$vuetify.breakpoint.smAndDown ? 'calc(100vh - 120px)' : '78vh'"
      fixed-header
      :footer-props="footerProps"
      theme="dark"
      :mobile-breakpoint="0"
      :server-items-length="totalItems"
      :items-per-page="itemsPerPage"
      @update:options="updateTableOptions"
    >
      <template v-slot:top>
        <!-- Desktop Toolbar -->
        <v-toolbar
          flat
          class="toolbar-container"
          v-if="!$vuetify.breakpoint.smAndDown"
        >
          <v-checkbox class="mt-5" @change="handleSelectAll" dense></v-checkbox>

          <v-btn
            class="ml-2"
            color="primary"
            :disabled="selectedItems.length === 0"
            @click="processSelectedItems"
          >
            Move to Inventory
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn-toggle class="mr-5" v-model="activeTable" mandatory>
            <v-btn value="new" @click="getInventory">New</v-btn>
            <v-btn value="history" @click="getInventoryHistory">History</v-btn>
          </v-btn-toggle>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="search-field"
            outlined
            dense
            @input="getInventory()"
          ></v-text-field>
        </v-toolbar>

        <!-- Mobile Toolbar for "new" table -->
        <v-toolbar flat class="mobile-toolbar" v-else>
          <div class="mobile-actions">
            <div class="mobile-top-row">
              <div class="d-flex align-center">
                <v-checkbox
                  @change="handleSelectAll"
                  dense
                  class="mt-0 pt-0 mr-2"
                ></v-checkbox>
                <v-btn
                  color="primary"
                  class="mobile-btn mobile-inventory-btn"
                  :disabled="selectedItems.length === 0"
                  @click="processSelectedItems"
                >
                  Move to Inventory
                </v-btn>
              </div>

              <v-btn-toggle
                class="mobile-toggle"
                v-model="activeTable"
                mandatory
                dense
              >
                <v-btn value="new" @click="getInventory" small>New</v-btn>
                <v-btn value="history" @click="getInventoryHistory" small
                  >History</v-btn
                >
              </v-btn-toggle>
            </div>

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mobile-search mt-2"
              outlined
              dense
              @input="getInventory()"
            ></v-text-field>
          </div>
        </v-toolbar>
      </template>

      <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.smAndDown">
        <tr :class="getRowClass(item)">
          <td>
            <v-card flat class="pa-3">
              <div class="d-flex justify-space-between align-center">
                <div>
                  <v-checkbox
                    v-model="selectedItems"
                    :value="item.upc"
                    dense
                    class="mt-0 pt-0"
                  ></v-checkbox>
                </div>
                <div class="flex-grow-1 ml-2">
                  <div class="font-weight-bold">UPC: {{ item.upc || "-" }}</div>
                  <div class="mobile-description">
                    Description: {{ formatDescription(item.description) }}
                  </div>
                  <div>Base Unit: {{ item.base_unit || "-" }}</div>
                  <div>Retail: {{ item.retail || "-" }}</div>
                  <div>Brand: {{ item.brand || "-" }}</div>
                </div>
                <div>
                  <v-btn icon small @click="viewInventoryDetail(item)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn icon small @click="editInventory(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon small @click="deleteInventory(item)" color="red">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </td>
        </tr>
      </template>

      <template v-slot:body="{ items }" v-if="!$vuetify.breakpoint.smAndDown">
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="item.upc"
            :class="getRowClass(item)"
          >
            <td>
              <v-checkbox
                v-model="selectedItems"
                :value="item.upc"
                dense
              ></v-checkbox>
            </td>
            <td>{{ index + 1 }}</td>
            <td>
              <span class="text-align-center no-wrap">{{
                item?.upc ? item.upc : "-"
              }}</span>
            </td>

            <td>
              <span class="text-align-center no-wrap">{{
                item?.description ? item.description : "-"
              }}</span>
            </td>

            <td>
              <span class="text-align-center no-wrap">{{
                item?.pack ? item.pack : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item?.size ? item.size : "-"
              }}</span>
            </td>

            <td>
              <span class="text-align-center no-wrap">{{
                item?.retail ? item.retail : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item?.base_unit ? item.base_unit : "-"
              }}</span>
            </td>

            <td>
              <span class="text-align-center no-wrap">{{
                item?.net_cost ? item.net_cost : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item?.department?.name ? item.department?.name : "-"
              }}</span>
            </td>

            <td>
              <span class="text-align-center no-wrap">{{
                item?.upc_case ? item.upc_case : "-"
              }}</span>
            </td>

            <td>
              <span class="text-align-center no-wrap">{{
                item?.base_case ? item.base_case : "-"
              }}</span>
            </td>

            <td>
              <span class="text-align-center no-wrap">{{
                formattedDate(item.created_at)
              }}</span>
            </td>
            <td>
              <span class="no-wrap">
                <span>
                  <v-icon @click="viewInventoryDetail(item)">mdi-eye</v-icon>
                </span>
                <span class="ml-1"
                  ><v-icon @click="editInventory(item)"
                    >mdi-pencil</v-icon
                  ></span
                >
                <span
                  ><v-icon class="text-red" @click="deleteInventory(item)"
                    >mdi-delete</v-icon
                  ></span
                >
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <v-data-table
      v-else
      :headers="getResponsiveHistoryHeaders"
      :items="historyItems"
      :search="search"
      item-key="upc"
      class="elevation-1"
      :loading="loading"
      striped
      :height="$vuetify.breakpoint.smAndDown ? 'calc(100vh - 120px)' : '78vh'"
      fixed-header
      :footer-props="footerProps"
      theme="dark"
      :mobile-breakpoint="0"
      :server-items-length="totalItems"
      :items-per-page="itemsPerPage"
      @update:options="updateTableOptions"
    >
      <template v-slot:top>
        <!-- Desktop Toolbar -->
        <v-toolbar
          flat
          class="toolbar-container"
          v-if="!$vuetify.breakpoint.smAndDown"
        >
          <v-btn @click="downloadCSV" class="primary mr-2">
            <v-icon left>mdi-download</v-icon>
            Download BR CSV
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn-toggle class="mr-5" v-model="activeTable" mandatory>
            <v-btn value="new" @click="getInventory">New</v-btn>
            <v-btn value="history" @click="getInventoryHistory">History</v-btn>
          </v-btn-toggle>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="search-field"
            outlined
            dense
            @input="getInventoryHistory()"
          ></v-text-field>
        </v-toolbar>

        <!-- Mobile Toolbar for "history" table -->
        <v-toolbar flat class="mobile-toolbar" v-else>
          <div class="mobile-actions">
            <div class="mobile-top-row">
              <v-btn @click="downloadCSV" class="primary mobile-btn">
                <v-icon left>mdi-download</v-icon>
                Download
              </v-btn>

              <v-btn-toggle
                class="mobile-toggle"
                v-model="activeTable"
                mandatory
                dense
              >
                <v-btn value="new" @click="getInventory" small>New</v-btn>
                <v-btn value="history" @click="getInventoryHistory" small
                  >History</v-btn
                >
              </v-btn-toggle>
            </div>

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mobile-search mt-2"
              outlined
              dense
              @input="getInventoryHistory()"
            ></v-text-field>
          </div>
        </v-toolbar>
      </template>

      <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.smAndDown">
        <tr :class="getRowClass(item)">
          <td>
            <v-card flat class="pa-3">
              <div class="d-flex justify-space-between align-center">
                <div class="flex-grow-1">
                  <div class="font-weight-bold">UPC: {{ item.upc || "-" }}</div>
                  <div class="mobile-description">
                    Description: {{ formatDescription(item.description) }}
                  </div>
                  <div>Brand: {{ item.brand || "-" }}</div>
                  <div>Base Unit: {{ item.base_unit || "-" }}</div>
                  <div>Retail: {{ item.retail || "-" }}</div>
                  <div>Added: {{ formattedDate(item.created_at) }}</div>
                  <div>By: {{ item.performed_by || "-" }}</div>
                </div>
              </div>
            </v-card>
          </td>
        </tr>
      </template>

      <template v-slot:body="{ items }" v-if="!$vuetify.breakpoint.smAndDown">
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="item.upc"
            :class="getRowClass(item)"
          >
            <td>{{ index + 1 }}</td>
            <td>
              <span class="text-align-center no-wrap">{{
                item?.upc ? item.upc : "-"
              }}</span>
            </td>

            <td>
              <span class="text-align-center no-wrap">{{
                item?.description ? item.description : "-"
              }}</span>
            </td>

            <td>
              <span class="text-align-center no-wrap">{{
                item?.pack ? item.pack : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item?.size ? item.size : "-"
              }}</span>
            </td>

            <td>
              <span class="text-align-center no-wrap">{{
                item?.retail ? item.retail : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item?.base_unit ? item.base_unit : "-"
              }}</span>
            </td>

            <td>
              <span class="text-align-center no-wrap">{{
                item?.net_cost ? item.net_cost : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item?.department?.name ? item.department?.name : "-"
              }}</span>
            </td>

            <td>
              <span class="text-align-center no-wrap">{{
                item?.upc_case ? item.upc_case : "-"
              }}</span>
            </td>

            <td>
              <span class="text-align-center no-wrap">{{
                item?.base_case ? item.base_case : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                item.performed_by ? item.performed_by : "-"
              }}</span>
            </td>
            <td>
              <span class="text-align-center no-wrap">{{
                formattedDate(item.created_at)
              }}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <view-inventory-dialog
      v-if="showViewInventoryDialog"
      v-model="showViewInventoryDialog"
      :inventoryItem="selectedInventory"
      @close-dialog="showViewInventoryDialog = false"
    />

    <edit-inventory-dialog
      v-if="showEditInventoryDialog"
      v-model="showEditInventoryDialog"
      :inventoryItem="selectedInventory"
      @close-dialog="showEditInventoryDialog = false"
      @reload-inventory="getInventory"
      @show-alert="showAlertData"
    />

    <delete-inventory-dialog
      v-if="showDeleteInventoryDialog"
      v-model="showDeleteInventoryDialog"
      :inventoryItem="selectedInventory"
      @close-dialog="showDeleteInventoryDialog = false"
      @reload-inventory="getInventory"
      @show-alert="showAlertData"
    />

    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
  </v-container>
</template>

<script>
import ViewInventoryDialog from "@/components/NewItems/ViewNewItemDialog";
import EditInventoryDialog from "@/components/NewItems/EditNewItemDialog";
import DeleteInventoryDialog from "@/components/NewItems/DeleteNewItemDialog";
import AlertComponent from "@/components/common/AlertComponent";

export default {
  name: "listing-data",
  data() {
    return {
      selectedItems: [],
      selectAll: [],
      activeTable: "new",
      search: "",
      loading: false,
      itemsPerPage: 20,
      counter: 0,
      showAlert: false,
      alertData: {},
      headers: [
        {
          text: "",
          value: "",
          sortable: false,
          class: "table-header",
        },
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        { text: "UPC", value: "upc", class: "table-header", sortable: true },
        {
          text: "Description",
          value: "description",
          class: "table-header",
          width: "15%",
          sortable: true,
        },
        { text: "Pack", value: "pack", class: "table-header", sortable: true },
        { text: "Size", value: "size", class: "table-header", sortable: true },
        {
          text: "Retail",
          value: "retail",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Base Unit",
          value: "base_unit",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Net Cost",
          value: "net_cost",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Dept Description",
          value: "dept_description",
          class: "table-header",
          width: "8%",
          sortable: true,
        },
        {
          text: "UPC Case",
          value: "upc_case",
          class: "table-header",
          width: "8%",
          sortable: true,
        },
        {
          text: "Base Case",
          value: "base_case",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
        },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
          width: "10%",
        },
      ],
      historyHeaders: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        { text: "UPC", value: "upc", class: "table-header", sortable: true },

        {
          text: "Description",
          value: "description",
          class: "table-header",
          width: "15%",
          sortable: true,
        },

        { text: "Pack", value: "pack", class: "table-header", sortable: true },
        { text: "Size", value: "size", class: "table-header", sortable: true },

        {
          text: "Retail",
          value: "retail",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Base Unit",
          value: "base_unit",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Net Cost",
          value: "net_cost",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Dept Description",
          value: "dept_description",
          class: "table-header",
          width: "8%",
          sortable: true,
        },
        {
          text: "UPC Case",
          value: "upc_case",
          class: "table-header",
          width: "8%",
          sortable: true,
        },
        {
          text: "Base Case",
          value: "base_case",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Performed By",
          value: "performed_by",
          class: "table-header",
        },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
        },
      ],
      footerProps: {
        "items-per-page-options": [20, 50, 100, 500],
      },
      items: [],
      historyItems: [],
      showAddInventoryDialog: false,
      showViewInventoryDialog: false,
      showEditInventoryDialog: false,
      showDeleteInventoryDialog: false,
      selectedInventory: {},
      currentPage: 1,
      totalItems: 0,
      sortBy: "created_at",
      sortDesc: true,
    };
  },
  components: {
    ViewInventoryDialog,
    EditInventoryDialog,
    AlertComponent,
    DeleteInventoryDialog,
  },
  mounted() {
    document.title = "Razcofoods | New Items";
    this.sortBy = "created_at";
    this.sortDesc = true;
    this.getInventory();
  },
  computed: {
    isAllSelected() {
      return this.selectedItems.length === this.items.length;
    },
    getResponsiveHeaders() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return [
          {
            text: "Item Details",
            value: "upc",
            sortable: false,
            class: "table-header",
          },
        ];
      }
      return this.headers;
    },
    getResponsiveHistoryHeaders() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return [
          {
            text: "Item Details",
            value: "upc",
            sortable: false,
            class: "table-header",
          },
        ];
      }
      return this.historyHeaders;
    },
  },
  methods: {
    async getInventory() {
      this.loading = true;
      await this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/new-items?page=" +
            this.currentPage +
            "&per_page=" +
            this.itemsPerPage +
            "&search=" +
            this.search +
            "&sort_by=" +
            this.sortBy +
            "&sort_desc=" +
            this.sortDesc
        )

        .then((res) => {
          if (res.data && res.data.status) {
            this.items = res.data.data.data;
            this.totalItems = res.data.data.total;
          } else {
            this.items = res.data.data;
            this.totalItems = 0;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;
    },
    formatDescription(description) {
      if (!description) return "-";

      const words = description.split(" ");
      if (words.length <= 3) return description;

      // Take the first three words and add ellipsis
      return words.slice(0, 3).join(" ") + "...";
    },
    async getInventoryHistory() {
      this.loading = true;
      await this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/new-items-history?page=" +
            this.currentPage +
            "&per_page=" +
            this.itemsPerPage +
            "&search=" +
            this.search +
            "&sort_by=" +
            this.sortBy +
            "&sort_desc=" +
            this.sortDesc
        )

        .then((res) => {
          if (res.data && res.data.status) {
            this.historyItems = res.data.data.data;
            this.totalItems = res.data.data.total;
          } else {
            this.items = res.data.data;
            this.totalItems = 0;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;
    },
    downloadCSV() {
      const csvContent = this.generateCSV();
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "new_items_br_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    generateCSV() {
      // Define the columns you want to include
      const desiredColumns = [
        "upc",
        "description",
        "wh_item",
        "retail",
        "upc_case",
        "net_cost",
        "pack",
        "size",
      ];

      // Get headers for only the desired columns
      const headers = this.historyHeaders
        .filter((h) => desiredColumns.includes(h.value))
        .map((header) => header.text)
        .join(",");

      // Map through historyItems to create rows with only desired columns
      const rows = this.historyItems.map((item) => {
        return desiredColumns
          .map((column) => {
            if (column.includes(".")) {
              const [parent, child] = column.split(".");
              return item[parent] ? item[parent][child] || "-" : "-";
            }
            return item[column] || "-";
          })
          .join(",");
      });
      // Combine headers and rows
      return [headers, ...rows].join("\n");
    },
    async processSelectedItems() {
      const matchedItems = this.items.filter((item) =>
        this.selectedItems.includes(item.upc)
      );

      await this.$axios
        .post(process.env.VUE_APP_API_URL + "/new-items/move-to-inventory", {
          items: matchedItems,
        })
        .then((res) => {
          if (res.data && res.data.status) {
            this.getInventory();
            this.selectedItems = [];
            this.alertData.alertText = res.data.message;
            this.alertData.alertType = "success";
          } else {
            this.alertData.alertText = res.data.message;
            this.alertData.alertType = "error";
          }
        });

      this.showAlert = true;
    },
    handleSelectAll(event) {
      if (event) {
        this.selectedItems = this.items.map((item) => item.upc);
      } else {
        this.selectedItems = [];
      }

      console.log(this.selectedItems);
    },
    getRowClass(item) {
      if (item.retail <= 0) {
        return "";
      }
      return "";
    },
    updateTableOptions({ page, itemsPerPage, sortBy, sortDesc }) {
      if (
        this.currentPage !== page ||
        this.itemsPerPage !== itemsPerPage ||
        this.sortBy !== sortBy ||
        this.sortDesc !== sortDesc
      ) {
        this.currentPage = page;
        this.itemsPerPage = itemsPerPage;
        this.sortBy = sortBy;
        this.sortDesc = sortDesc;
        this.getInventory();
      }
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    showAlertData(alertData) {
      this.alertData = alertData;
      this.showAlert = true;
    },
    viewInventoryDetail(item) {
      this.selectedInventory = item;
      this.showViewInventoryDialog = true;
    },
    editInventory(item) {
      this.selectedInventory = item;
      this.showEditInventoryDialog = true;
    },
    deleteInventory(item) {
      this.selectedInventory = item;
      this.showDeleteInventoryDialog = true;
    },
  },
};
</script>

<style>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  text-wrap: nowrap !important;
  white-space: nowrap !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
  text-wrap: nowrap !important;
}

.v-application .primary--text {
  color: #8b1a29 !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #8b1a29 !important;
}

.text-red {
  color: red;
}

.text-white {
  color: white;
}

.no-wrap {
  white-space: nowrap;
}

.text-red {
  color: red !important;
}

.zero-retail {
  background-color: #95081b;
  color: white;
}

.zero-retail:hover {
  background-color: #bb0d24 !important;
}

.v-btn--active {
  background-color: #8b1a29 !important;
  color: #fff !important;
}

.compact-checkbox ::v-deep .v-input__slot {
  margin-bottom: 0;
  transform: scale(0.8);
}

.compact-checkbox ::v-deep .v-input__control {
  min-height: 0 !important;
}

.compact-checkbox ::v-deep .v-input {
  margin-top: 0;
  padding-top: 0;
}

/* Desktop Toolbar Styles */
.toolbar-container {
  padding: 8px 16px;
}

.search-field {
  max-width: 300px;
}

/* Mobile Toolbar Styles */
.mobile-toolbar {
  padding: 32px 8px !important;
  height: auto !important;
}

.mobile-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mobile-top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mobile-btn {
  height: 36px;
  font-size: 12px;
  white-space: nowrap;
}

.mobile-toggle {
  margin-left: 8px;
}

.mobile-search {
  width: 100%;
}

/* Make checkboxes more compact on mobile */
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 16px;
}

@media (max-width: 600px) {
  .v-data-table {
    font-size: 14px;
  }

  .v-toolbar {
    padding: 8px;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .v-data-table {
    font-size: 15px;
  }
}

.mobile-description {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  word-break: break-word;
}

/* Make sure the full description is shown in the view dialog */
.v-dialog .mobile-description {
  white-space: normal;
  word-break: normal;
  max-width: none;
}

.mobile-inventory-btn {
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px;
  font-size: 9px !important;
}

.toolbar-container {
  padding: 8px 16px;
  margin-bottom: 16px !important; /* Add space between toolbar and table */
}

.v-btn-toggle {
  background-color: #f5f5f5;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 16px !important; /* Increase right margin */
}

.v-btn-toggle .v-btn {
  border-radius: 0;
  height: 36px;
  min-width: 80px;
}

.v-btn-toggle .v-btn.v-btn--active {
  background-color: #8b1a29 !important;
  color: white !important;
}
</style>
