<template>
  <v-container fluid class="pa-0 pa-sm-2">
    <v-data-table
      :headers="getResponsiveHeaders"
      :items="items"
      :search="search"
      item-key="id"
      class="elevation-1"
      :loading="loading"
      striped
      :height="$vuetify.breakpoint.smAndDown ? 'calc(100vh - 120px)' : '78vh'"
      fixed-header
      :footer-props="footerProps"
      :mobile-breakpoint="0"
    >
      <template v-slot:top>
        <!-- Desktop Toolbar -->
        <v-toolbar flat v-if="!$vuetify.breakpoint.smAndDown">
          <v-btn
            color="#8b1a29"
            class="ml-2 text-white"
            @click="showAddUserDialog = true"
          >
            <v-icon left>mdi-plus</v-icon>
            Add User
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            outlined
            dense
            class="search-field"
          ></v-text-field>
        </v-toolbar>
        
        <!-- Mobile Toolbar -->
        <v-toolbar flat class="mobile-toolbar" v-else>
          <div class="mobile-actions">
            <v-btn
              color="#8b1a29"
              class="text-white full-width-btn mb-2"
              @click="showAddUserDialog = true"
            >
              <v-icon left>mdi-plus</v-icon>
              Add User
            </v-btn>

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mobile-search"
              outlined
              dense
            ></v-text-field>
          </div>
        </v-toolbar>
      </template>
      
      <!-- Mobile view for items -->
      <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.smAndDown">
        <tr>
          <td>
            <v-card flat class="pa-3 mobile-user-card">
              <div class="d-flex justify-space-between align-center">
                <div class="flex-grow-1">
                  <div class="font-weight-bold">{{ item.name || "-" }}</div>
                  <div>Email: {{ item.email || "-" }}</div>
                  <div>Phone: {{ item.phone || "-" }}</div>
                  <div>Address: {{ item.address || "-" }}</div>
                  <div>Added: {{ formattedDate(item.created_at) }}</div>
                </div>
                <div class="mobile-actions-column">
                  <v-btn icon small @click="viewUserDetail(item)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn icon small @click="editUser(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon small @click="deleteUser(item)" color="red">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </td>
        </tr>
      </template>

      <!-- Desktop view for items -->
      <template v-slot:body="{ items }" v-if="!$vuetify.breakpoint.smAndDown">
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id">
            <td>{{ index + 1 }}</td>
            <td>{{ item.name || "-" }}</td>
            <td>{{ item.email || "-" }}</td>
            <td class="">{{ item.phone || "-" }}</td>
            <td class="">{{ item.address || "-" }}</td>
            <td>{{ formattedDate(item.created_at) }}</td>
            <td>
              <span>
                <v-icon @click="viewUserDetail(item)">mdi-eye</v-icon>
              </span>
              <span class="ml-1">
                <v-icon @click="editUser(item)">mdi-pencil</v-icon>
              </span>
              <span>
                <v-icon class="text-red" @click="deleteUser(item)">mdi-delete</v-icon>
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <add-user-dialog
      v-if="showAddUserDialog"
      @close-dialog="showAddUserDialog = false"
      @reload-users="getUsers"
      @show-alert="showAlertData"
    />

    <view-User-dialog
      v-if="showViewUserDialog"
      v-model="showViewUserDialog"
      :user="selectedUser"
      @close-dialog="showViewUserDialog = false"
    />

    <edit-user-dialog
      v-if="showEditUserDialog"
      v-model="showEditUserDialog"
      :user="selectedUser"
      @close-dialog="showEditUserDialog = false"
      @reload-users="getUsers"
      @show-alert="showAlertData"
    />

    <delete-user-dialog
      v-if="showDeleteUserDialog"
      v-model="showDeleteUserDialog"
      :user="selectedUser"
      @close-dialog="showDeleteUserDialog = false"
      @reload-users="getUsers"
      @show-alert="showAlertData"
    />

    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
  </v-container>
</template>


<script>
import AddUserDialog from "@/components/Users/AddUserDialog";
import ViewUserDialog from "@/components/Users/ViewUserDialog";
import EditUserDialog from "@/components/Users/EditUserDialog";
import DeleteUserDialog from "@/components/Users/DeleteUserDialog";
import AlertComponent from "@/components/common/AlertComponent";

export default {
  name: "user-listing",
  data() {
    return {
      search: "",
      loading: false,
      itemsPerPage: 10,
      showAlert: false,
      alertData: {},
      headers: [
        { text: "Sr.#", value: "number", sortable: false, class: "table-header" },
        { text: "Name", value: "name", class: "table-header" },
        { text: "Email", value: "email", class: "table-header" },
        { text: "Phone", value: "phone", class: "table-header" },
        { text: "Address", value: "address", class: "table-header" },
        { text: "Added At", value: "created_at", class: "table-header", width: "12.5%" },
        { text: "Actions", value: "action", class: "table-header", width: "10%" },
      ],
      mobileHeaders: [
        {
          text: "User Details",
          value: "name",
          sortable: false,
          class: "table-header",
        },
      ],
      footerProps: {
        "items-per-page-options": [
          20,
          50,
          100,
          500,
          { text: "All", value: -1 },
        ],
      },
      items: [],
      showAddUserDialog: false,
      showViewUserDialog: false,
      showEditUserDialog: false,
      showDeleteUserDialog: false,
      selectedUser: {},
    };
  },
  components: {
    AddUserDialog,
    ViewUserDialog,
    EditUserDialog,
    DeleteUserDialog,
    AlertComponent,
  },
  mounted() {
    document.title = "Razcofoods | Users";
    this.getUsers();
  },
  computed: {
    getResponsiveHeaders() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return this.mobileHeaders;
      }
      return this.headers;
    }
  },
  methods: {
    async getUsers() {
      this.loading = true;
      let items = await this.$axios.get(process.env.VUE_APP_API_URL + "/users");
      if (items.data && items.data.status) {
        this.items = items.data.data;
      }
      this.loading = false;
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    showAlertData(alertData) {
      this.alertData = alertData;
      this.showAlert = true;
    },
    viewUserDetail(item) {
      this.selectedUser = item;
      this.showViewUserDialog = true;
    },
    editUser(item) {
      this.selectedUser = item;
      this.showEditUserDialog = true;
    },
    deleteUser(item) {
      this.selectedUser = item;
      this.showDeleteUserDialog = true;
    }
  },
};
</script>


<style scoped>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #fff !important;
}
.v-application .primary--text {
  color: #8b1a29 !important;
}
.v-application--is-ltr .v-text-field .v-label {
  color: #8b1a29 !important;
}
.text-red {
  color: red;
}
.text-white {
  color: white;
}

/* Mobile specific styles */
.search-field {
  max-width: 300px;
}

.mobile-toolbar {
  padding: 32px 8px !important;
  height: auto !important;
}

.mobile-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.mobile-search {
  width: 100%;
}

.full-width-btn {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
  height: 44px !important;
}

.mobile-user-card {
  margin-bottom: 8px;
}

.mobile-actions-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.mobile-actions-column .v-btn {
  margin: 4px 0;
}

@media (max-width: 600px) {
  .v-data-table {
    font-size: 14px;
  }

  .v-toolbar {
    padding: 8px;
  }
  
  .mobile-actions-column {
    margin-left: 8px;
  }
}
</style>

