<template>
  <v-container
    fluid
    class="pa-0"
    :class="{
      'mobile-container': $vuetify.breakpoint.smAndDown,
      'desktop-container': !$vuetify.breakpoint.smAndDown,
    }"
  >
    <!-- Desktop Toolbar -->
    <v-toolbar
      flat
      class="toolbar-container"
      v-if="!$vuetify.breakpoint.smAndDown"
    >
      <template v-if="activeTable === 'new'">
        <v-btn
          color="#8b1a29"
          class="ml-2 text-white"
          @click="showAddItemExpirationDialog = true"
        >
          <v-icon left>mdi-plus</v-icon>
          Add Item
        </v-btn>

        <!-- Add batch action buttons -->
        <v-menu
          v-model="batchActionsMenu"
          :close-on-content-click="false"
          offset-y
          transition="slide-y-transition"
          :disabled="!newItemsSelected.length"
          min-width="220"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#8b1a29"
              class="ml-2 text-white"
              :disabled="!newItemsSelected.length"
              v-bind="attrs"
              v-on="on"
              depressed
            >
              <span>Bulk Actions ({{ newItemsSelected.length }})</span>
              <v-icon right>{{
                batchActionsMenu ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </template>
          <v-card elevation="4" class="bulk-actions-menu">
            <v-list dense>
              <v-list-item-group color="#8b1a29">
                <v-list-item
                  @click="applyBulkAction('delete')"
                  class="bulk-action-item"
                >
                  <v-list-item-icon>
                    <v-icon color="red darken-2">mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Delete Selected</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item
                  @click="applyBulkAction('done')"
                  class="bulk-action-item"
                >
                  <v-list-item-icon>
                    <v-icon color="green darken-1">mdi-check-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Mark as Done</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item
                  @click="applyBulkAction('accept')"
                  class="bulk-action-item"
                >
                  <v-list-item-icon>
                    <v-icon color="blue darken-1">mdi-thumb-up</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Accept Discount</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item
                  @click="applyBulkAction('reject')"
                  class="bulk-action-item"
                >
                  <v-list-item-icon>
                    <v-icon color="orange darken-2">mdi-thumb-down</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Reject Discount</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
      </template>

      <v-spacer></v-spacer>

      <!-- New/History Toggle Button - Desktop -->
      <v-btn-toggle
        v-model="activeTable"
        mandatory
        class="mr-3 compact-toggle"
        dense
      >
        <v-btn value="new" small>New</v-btn>
        <v-btn value="history" small @click="getHistoryItems">History</v-btn>
      </v-btn-toggle>

      <!-- Dropdown for filtering by expiration -->
      <v-select
        v-model="selectedFilter"
        :items="filterOptions"
        @change="getItemExpiration"
        label="Filter by Expiration"
        dense
        outlined
        class="mr-3"
        hide-details
        style="max-width: 200px"
      ></v-select>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class="search-field"
        outlined
        dense
        @input="debouncedSearch"
      ></v-text-field>
    </v-toolbar>

    <!-- Mobile Toolbar -->
    <div class="mobile-toolbar-container" v-else>
      <!-- New Tab Mobile Layout -->
      <template v-if="activeTable === 'new'">
        <!-- First row: Add Item, Bulk Actions, and Tab Toggle -->
        <div class="mobile-toolbar-row">
          <v-btn
            color="#8b1a29"
            class="text-white mobile-compact-btn"
            @click="showAddItemExpirationDialog = true"
          >
            <v-icon small left>mdi-plus</v-icon>
            Add
          </v-btn>

          <!-- Mobile Bulk Actions Dropdown -->
          <v-menu
            v-model="batchActionsMenuMobile"
            offset-y
            :close-on-content-click="true"
            :disabled="!newItemsSelected.length"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#8b1a29"
                class="text-white mobile-compact-btn ml-1"
                :disabled="!newItemsSelected.length"
                v-bind="attrs"
                v-on="on"
              >
                <span>Actions ({{ newItemsSelected.length }})</span>
                <v-icon small right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="applyBulkAction('delete')">
                <v-list-item-icon>
                  <v-icon color="red">mdi-delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Delete Selected</v-list-item-title>
              </v-list-item>

              <v-list-item @click="applyBulkAction('done')">
                <v-list-item-icon>
                  <v-icon color="green">mdi-check-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Mark as Done</v-list-item-title>
              </v-list-item>

              <v-list-item @click="applyBulkAction('accept')">
                <v-list-item-icon>
                  <v-icon color="blue">mdi-thumb-up</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Accept Discount</v-list-item-title>
              </v-list-item>

              <v-list-item @click="applyBulkAction('reject')">
                <v-list-item-icon>
                  <v-icon color="orange">mdi-thumb-down</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Reject Discount</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <!-- New/History Toggle Button - Mobile -->
          <v-btn-toggle
            v-model="activeTable"
            mandatory
            class="mobile-tabs"
            dense
          >
            <v-btn value="new" class="mobile-tab-btn">New</v-btn>
            <v-btn
              value="history"
              class="mobile-tab-btn"
              @click="getHistoryItems"
              >History</v-btn
            >
          </v-btn-toggle>
        </div>

        <!-- Second row: Filter and Search -->
        <div class="mobile-toolbar-row">
          <v-select
            v-model="selectedFilter"
            :items="filterOptions"
            @change="getItemExpiration"
            label="Filter"
            dense
            outlined
            class="mobile-filter mr-2"
            hide-details
          ></v-select>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mobile-search"
            outlined
            dense
            @input="debouncedSearch"
          ></v-text-field>
        </div>
      </template>

      <!-- History Tab Mobile Layout -->
      <template v-else>
        <!-- First row: Tab Toggle -->
        <div class="mobile-toolbar-row">
          <v-btn-toggle
            v-model="activeTable"
            mandatory
            class="mobile-tabs"
            dense
          >
            <v-btn value="new" class="mobile-tab-btn">New</v-btn>
            <v-btn
              value="history"
              class="mobile-tab-btn"
              @click="getHistoryItems"
              >History</v-btn
            >
          </v-btn-toggle>

          <v-spacer></v-spacer>

          <!-- Additional actions for history view could go here -->
        </div>

        <!-- Second row: Filter and Search -->
        <div class="mobile-toolbar-row">
          <v-select
            v-model="selectedFilter"
            :items="filterOptions"
            @change="getHistoryItems"
            label="Filter"
            dense
            outlined
            class="mobile-filter mr-2"
            hide-details
          ></v-select>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mobile-search"
            outlined
            dense
            @input="debouncedSearch"
          ></v-text-field>
        </div>
      </template>
    </div>

    <!-- New Items Table -->
    <div v-if="activeTable === 'new'">
      <v-data-table
        v-if="activeTable === 'new'"
        v-model="newItemsSelected"
        show-select
        :headers="getResponsiveHeaders"
        :items="items"
        :search="search"
        item-key="id"
        class="elevation-1"
        :loading="loading"
        striped
        :height="$vuetify.breakpoint.smAndDown ? 'calc(100vh - 120px)' : '78vh'"
        fixed-header
        :footer-props="footerProps"
        :mobile-breakpoint="0"
        @input="handleSelectionChange"
      >
        <!-- Mobile item view -->
        <template
          v-slot:item="{ item, isSelected, select }"
          v-if="$vuetify.breakpoint.smAndDown"
        >
          <tr :class="getRowClass(item)" style="width: 100%">
            <td style="width: 100%; padding: 0">
              <v-card flat class="mobile-card pa-3 mb-2" style="width: 100%">
                <div class="d-flex align-center mb-2">
                  <!-- Checkbox on the left -->
                  <v-checkbox
                    :input-value="isSelected"
                    @click="select(!isSelected)"
                    hide-details
                    dense
                    class="mt-0 pt-0"
                  ></v-checkbox>

                  <!-- UPC and expiry date as primary info -->
                  <div class="ml-2 flex-grow-1">
                    <div class="d-flex justify-space-between align-center">
                      <div class="font-weight-bold">
                        UPC: {{ item.upc || "-" }}
                      </div>
                      <div class="expiry-chip" :class="getExpiryClass(item)">
                        {{
                          item.expiry_date
                            ? formattedDate(item.expiry_date)
                            : "No Expiry"
                        }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Description in its own row -->
                <div class="mobile-description mb-2 text-center">
                  <span class="font-weight-medium">Description:</span>
                  {{ item.description || "-" }}
                </div>

                <!-- Simplified single column layout for better readability -->
                <div class="mobile-info-list mb-2">
                  <div class="mobile-info-item">
                    <span class="font-weight-medium">Quantity:</span>
                    <span>{{ item.quantity || "-" }}</span>
                  </div>
                  <div class="mobile-info-item">
                    <span class="font-weight-medium">Aisle:</span>
                    <span>{{ item.aisle || "-" }}</span>
                  </div>
                  <div class="mobile-info-item">
                    <span class="font-weight-medium">Department:</span>
                    <span>{{ item.department?.name || "-" }}</span>
                  </div>
                  <div class="mobile-info-item">
                    <span class="font-weight-medium">Category:</span>
                    <span>{{ item.category?.name || "-" }}</span>
                  </div>
                  <div class="mobile-info-item">
                    <span class="font-weight-medium">Discount:</span>
                    <span>{{
                      item?.discount?.discount_percent
                        ? item?.discount?.discount_percent + "%"
                        : "-"
                    }}</span>
                  </div>
                  <div class="mobile-info-item">
                    <span class="font-weight-medium">Added:</span>
                    <span>{{
                      item.created_at
                        ? formattedDateShort(item.created_at)
                        : "-"
                    }}</span>
                  </div>
                </div>

                <!-- Price information in its own section -->
                <div class="price-info mb-2">
                  <div class="mobile-info-item">
                    <span class="font-weight-medium">Current:</span>
                    <span>{{
                      parseFloat(item?.discount?.old_retail_price).toFixed(2) ||
                      "-"
                    }}</span>
                  </div>
                  <div class="mobile-info-item">
                    <span class="font-weight-medium">Suggested:</span>
                    <span>{{
                      parseFloat(item?.discount?.new_retail_price).toFixed(2) ||
                      "-"
                    }}</span>
                  </div>
                </div>

                <!-- Action buttons in a row at the bottom -->
                <div class="d-flex justify-end">
                  <v-btn icon small @click="viewItemDetail(item)" class="mx-1">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn icon small @click="editItem(item)" class="mx-1">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    small
                    @click="DeleteItem(item)"
                    color="red"
                    class="mx-1"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-card>
            </td>
          </tr>
        </template>

        <!-- Desktop table view -->
        <template
          v-slot:body="{ items: displayItems }"
          v-if="!$vuetify.breakpoint.smAndDown"
        >
          <tbody>
            <tr v-for="(item, index) in displayItems" :key="item.id">
              <td>
                <v-checkbox
                  v-model="newItemsSelected"
                  :value="item"
                  hide-details
                  dense
                ></v-checkbox>
              </td>
              <td>{{ index + 1 }}</td>
              <td>
                <span class="text-align-center">{{
                  item.upc ? item.upc : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item.description ? item.description : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item.quantity ? item.quantity : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item.aisle ? item.aisle : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item.department?.name ? item.department?.name : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item.category?.name ? item.category?.name : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item.expiry_date ? formattedDate(item.expiry_date) : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item?.discount?.discount_percent
                    ? item.discount.discount_percent + "%"
                    : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item?.discount?.old_retail_price
                    ? parseFloat(item.discount.old_retail_price).toFixed(4)
                    : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item?.discount?.new_retail_price
                    ? parseFloat(item.discount.new_retail_price).toFixed(4)
                    : "-"
                }}</span>
              </td>
              <td>
                <span>{{
                  item.created_at ? formattedDate(item.created_at) : "-"
                }}</span>
              </td>
              <td>
                <span>
                  <v-icon @click="viewItemDetail(item)">mdi-eye</v-icon>
                </span>
                <span class="ml-1"
                  ><v-icon @click="editItem(item)">mdi-pencil</v-icon></span
                >
                <span
                  ><v-icon class="text-red" @click="DeleteItem(item)"
                    >mdi-delete</v-icon
                  ></span
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>

    <!-- History Items Table -->
    <div v-else>
      <v-data-table
        :headers="historyTableHeaders"
        :items="historyItems"
        :search="search"
        item-key="id"
        class="elevation-1"
        :loading="loading"
        striped
        :height="$vuetify.breakpoint.smAndDown ? 'calc(100vh - 120px)' : '78vh'"
        fixed-header
        :footer-props="footerProps"
        :mobile-breakpoint="0"
      >
        <!-- Mobile history item view -->
        <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.smAndDown">
          <tr style="width: 100%">
            <td style="width: 100%; padding: 0">
              <v-card flat class="mobile-card pa-3 mb-2" style="width: 100%">
                <div class="d-flex justify-space-between align-center mb-2">
                  <div class="font-weight-bold">UPC: {{ item.upc || "-" }}</div>
                  <div class="expiry-chip" :class="getExpiryClass(item)">
                    {{
                      item.expiry_date
                        ? formattedDate(item.expiry_date)
                        : "No Expiry"
                    }}
                  </div>
                </div>

                <!-- Description in its own row -->
                <div class="mobile-description mb-2 text-center">
                  <span class="font-weight-medium">Description:</span>
                  {{ item.description || "-" }}
                </div>

                <!-- Status chip -->
                <div class="d-flex justify-center mb-2">
                  <v-chip
                    v-if="item.action === 'accept'"
                    color="green"
                    text-color="white"
                    small
                  >
                    Accepted
                  </v-chip>
                  <v-chip
                    v-else-if="item.action === 'done'"
                    color="yellow"
                    text-color="black"
                    small
                  >
                    Done
                  </v-chip>
                  <v-chip
                    v-else-if="item.action === 'reject'"
                    color="red"
                    text-color="black"
                    small
                  >
                    Rejected
                  </v-chip>
                  <v-chip v-else color="blue" text-color="white" small>
                    {{ item.action }}
                  </v-chip>
                </div>

                <!-- Simplified single column layout for better readability -->
                <div class="mobile-info-list mb-2">
                  <div class="mobile-info-item">
                    <span class="font-weight-medium">Quantity:</span>
                    <span>{{ item.quantity || "-" }}</span>
                  </div>
                  <div class="mobile-info-item">
                    <span class="font-weight-medium">Aisle:</span>
                    <span>{{ item.aisle || "-" }}</span>
                  </div>
                  <div class="mobile-info-item">
                    <span class="font-weight-medium">Department:</span>
                    <span>{{ item.department?.name || "-" }}</span>
                  </div>
                  <div class="mobile-info-item">
                    <span class="font-weight-medium">Category:</span>
                    <span>{{ item.category?.name || "-" }}</span>
                  </div>
                  <div class="mobile-info-item">
                    <span class="font-weight-medium">Discount:</span>
                    <span>{{
                      item?.discount?.discount_percent
                        ? item?.discount?.discount_percent + "%"
                        : "-"
                    }}</span>
                  </div>
                  <div class="mobile-info-item">
                    <span class="font-weight-medium">Performed By:</span>
                    <span>{{ item.user?.name || "-" }}</span>
                  </div>
                  <div class="mobile-info-item">
                    <span class="font-weight-medium">Updated:</span>
                    <span>{{
                      item.updated_at
                        ? formattedDateShort(item.updated_at)
                        : "-"
                    }}</span>
                  </div>
                </div>

                <!-- Price information in its own section -->
                <div class="price-info mb-2">
                  <div class="mobile-info-item">
                    <span class="font-weight-medium">Old Retail:</span>
                    <span>{{
                      parseFloat(item?.discount?.old_retail_price).toFixed(2) ||
                      "-"
                    }}</span>
                  </div>
                  <div class="mobile-info-item">
                    <span class="font-weight-medium">New Retail:</span>
                    <span>{{
                      parseFloat(item?.discount?.new_retail_price).toFixed(2) ||
                      "-"
                    }}</span>
                  </div>
                </div>
              </v-card>
            </td>
          </tr>
        </template>

        <!-- Desktop history table view -->
        <template
          v-slot:body="{ items: displayItems }"
          v-if="!$vuetify.breakpoint.smAndDown"
        >
          <tbody>
            <tr v-for="(item, index) in displayItems" :key="item.id">
              <td>{{ index + 1 }}</td>
              <td>
                <span class="text-align-center">{{
                  item.upc ? item.upc : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item.description ? item.description : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item.quantity ? item.quantity : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item.aisle ? item.aisle : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item.department?.name ? item.department?.name : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item.category?.name ? item.category?.name : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item.expiry_date ? formattedDate(item.expiry_date) : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item?.discount?.discount_percent
                    ? item?.discount?.discount_percent
                    : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item?.discount?.old_retail_price
                    ? parseFloat(item?.discount?.old_retail_price).toFixed(4)
                    : "-"
                }}</span>
              </td>
              <td>
                <span class="text-align-center">{{
                  item?.discount?.new_retail_price
                    ? parseFloat(item?.discount?.new_retail_price).toFixed(4)
                    : "-"
                }}</span>
              </td>
              <td>
                <v-chip
                  v-if="item.action === 'done'"
                  color="yellow"
                  text-color="black"
                  small
                >
                  Done
                </v-chip>
                <v-chip
                  v-else-if="item.action === 'accept'"
                  color="green"
                  text-color="white"
                  small
                >
                  Accepted
                </v-chip>
                <v-chip
                  v-else-if="item.action === 'reject'"
                  color="red"
                  text-color="white"
                  small
                >
                  Rejected
                </v-chip>
                <span v-else>-</span>
              </td>
              <td>
                <span>{{ item.performed_by || "-" }}</span>
              </td>
              <td>
                <span>{{
                  item.updated_at ? formattedDate(item.updated_at) : "-"
                }}</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>

    <add-item-expiration-dialog
      v-if="showAddItemExpirationDialog"
      @close-dialog="showAddItemExpirationDialog = false"
      @reload-inventory="getItemExpiration"
      @show-alert="showAlertData"
    />

    <view-item-dialog
      v-if="showViewItemDialog"
      v-model="showViewItemDialog"
      :item="selectedItem"
      @close-dialog="showViewItemDialog = false"
    />

    <edit-item-dialog
      v-if="showEditItemDialog"
      v-model="showEditItemDialog"
      :item="selectedItem"
      @close-dialog="showEditItemDialog = false"
      @reload-vendors="getItemExpiration"
      @show-alert="showAlertData"
    />

    <delete-item-dialog
      v-if="showDeleteItemDialog"
      v-model="showDeleteItemDialog"
      :item="selectedItem"
      @close-dialog="showDeleteItemDialog = false"
      @reload-vendors="getItemExpiration"
      @show-alert="showAlertData"
    />

    <bulk-action-dialog
      v-if="showBulkActionDialog"
      v-model="showBulkActionDialog"
      :itemIds="selectedItemIds"
      :action="action"
      @close-dialog="showBulkActionDialog = false"
      @reload-vendors="getItemExpiration"
      @show-alert="showAlertData"
    />

    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
  </v-container>
</template>

<script>
import AddItemExpirationDialog from "@/components/ItemExpiration/AddItemExpiration";
import ViewItemDialog from "@/components/ItemExpiration/ViewItemExpiration";
import EditItemDialog from "@/components/ItemExpiration/EditItemExpiration";
import DeleteItemDialog from "@/components/ItemExpiration/DeleteItemExpiration";
import AlertComponent from "@/components/common/AlertComponent";
import BulkActionDialog from "@/components/ItemExpiration/BulkActionDialog";
import _ from "lodash";

export default {
  name: "vendor-listing",
  data() {
    return {
      search: "",
      loading: false,
      itemsPerPage: 10,
      counter: 0,
      showAlert: false,
      alertData: {},
      newItemsSelected: [], // Changed from selected to newItemsSelected
      showBulkActionDialog: false,
      batchActionsMenu: false,
      batchActionsMenuMobile: false,
      selectedItemIds: [],
      action: "",
      activeTable: "new", // Toggle between "new" and "history"
      headers: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        {
          text: "UPC",
          value: "upc",
          class: "table-header",
        },
        { text: "Description", value: "description", class: "table-header" },
        { text: "Quantity", value: "quantity", class: "table-header" },
        { text: "Aisle", value: "aisle", class: "table-header" },
        {
          text: "Department",
          value: "department",
          class: "table-header",
        },
        {
          text: "Category",
          value: "category",
          class: "table-header",
        },
        {
          text: "Expiry Date",
          value: "expiry_date",
          class: "table-header",
        },
        {
          text: "Discount %",
          value: "disscount_percentage",
          class: "table-header",
        },
        {
          text: "Current Retail",
          value: "current_retail",
          class: "table-header",
        },
        {
          text: "Suggested Retail",
          value: "new_retail",
          class: "table-header",
        },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
        },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
          width: "10%",
        },
      ],
      historyTableHeaders: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        {
          text: "UPC",
          value: "upc",
          class: "table-header",
        },
        { text: "Description", value: "description", class: "table-header" },
        { text: "Quantity", value: "quantity", class: "table-header" },
        { text: "Aisle", value: "aisle", class: "table-header" },
        {
          text: "Department",
          value: "department",
          class: "table-header",
        },
        {
          text: "Category",
          value: "category",
          class: "table-header",
        },
        {
          text: "Expiry Date",
          value: "expiry_date",
          class: "table-header",
        },
        {
          text: "Discount %",
          value: "disscount_percentage",
          class: "table-header",
        },
        {
          text: "Old Retail",
          value: "current_retail",
          class: "table-header",
        },
        {
          text: "New Retail",
          value: "new_retail",
          class: "table-header",
        },
        {
          text: "Status",
          value: "status",
          class: "table-header",
        },
        {
          text: "Performed By",
          value: "user",
          class: "table-header",
        },
        {
          text: "Updated At",
          value: "updated_at",
          class: "table-header",
        },
      ],
      footerProps: {
        "items-per-page-options": [
          20,
          50,
          100,
          500,
          { text: "All", value: -1 },
        ],
      },
      items: [],
      historyItems: [],
      showAddItemExpirationDialog: false,
      showViewItemDialog: false,
      showEditItemDialog: false,
      showDeleteItemDialog: false,
      selectedItem: {},
      filterOptions: [
        { text: "10 Days", value: 10 },
        { text: "20 Days", value: 20 },
        { text: "30 Days", value: 30 },
        { text: "All", value: "all" },
      ],
      selectedFilter: "all",
    };
  },
  components: {
    AddItemExpirationDialog,
    ViewItemDialog,
    EditItemDialog,
    AlertComponent,
    DeleteItemDialog,
    BulkActionDialog,
  },
  mounted() {
    document.title = "Razcofoods | Items Expiration";
    this.newItemsSelected = []; // Initialize as empty array
    this.selectedItemIds = [];
    this.action = "";
    this.getItemExpiration();
  },
  computed: {
    getResponsiveHeaders() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return [
          {
            text: "Item Details",
            value: "upc",
            sortable: false,
            class: "table-header",
          },
        ];
      }
      return this.headers;
    },
  },
  watch: {
    activeTable(newValue) {
      // Reset selection when switching tabs
      this.newItemsSelected = [];
      this.selectedItemIds = [];

      if (newValue === "history") {
        this.getHistoryItems();
      } else {
        this.getItemExpiration();
      }
    },
  },
  methods: {
    debouncedSearch: _.debounce(function () {
      if (this.activeTable === "new") {
        this.getItemExpiration();
      } else {
        this.getHistoryItems();
      }
    }, 500),
    async getItemExpiration() {
      this.loading = true;
      // Reset selection state
      this.newItemsSelected = [];
      this.selectedItemIds = [];
      this.action = "";

      let items = await this.$axios.get(
        process.env.VUE_APP_API_URL +
          "/item-expiration" +
          "?filter_days=" +
          this.selectedFilter +
          "&search=" +
          this.search
      );
      if (items.data && items.data.status) {
        this.items = items.data.data;
      }
      this.loading = false;
    },
    async getHistoryItems() {
      this.loading = true;
      // Reset selection state
      this.newItemsSelected = [];
      this.selectedItemIds = [];
      this.action = "";

      let items = await this.$axios.get(
        process.env.VUE_APP_API_URL +
          "/item-expiration" +
          "?filter_days=" +
          this.selectedFilter +
          "&history=true" +
          "&search=" +
          this.search
      );
      if (items.data && items.data.status) {
        this.historyItems = items.data.data;
      }
      this.loading = false;
    },
    handleSelectionChange(selectedItems) {
      // Create a new array to avoid reactivity issues
      this.newItemsSelected = [...selectedItems];
    },
    formatDescription(description) {
      if (!description) return "-";

      const words = description.split(" ");
      if (words.length <= 3) return description;

      // Take the first three words and add ellipsis
      return words.slice(0, 3).join(" ") + "...";
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    formattedDateShort(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });
    },

    getExpiryClass(item) {
      if (!item.expiry_date) return "no-expiry";

      const today = new Date();
      const expiryDate = new Date(item.expiry_date);
      const daysUntilExpiry = Math.floor(
        (expiryDate - today) / (1000 * 60 * 60 * 24)
      );

      if (daysUntilExpiry < 0) return "expired";
      if (daysUntilExpiry <= 10) return "expiring-soon";
      return "valid-expiry";
    },
    showAlertData(alertData) {
      this.alertData = alertData;
      this.showAlert = true;
    },
    viewItemDetail(item) {
      this.selectedItem = item;
      this.showViewItemDialog = true;
    },
    editItem(item) {
      this.selectedItem = item;
      this.showEditItemDialog = true;
    },
    DeleteItem(item) {
      this.selectedItem = item;
      this.showDeleteItemDialog = true;
    },
    getRowClass() {
      return "";
    },
    applyBulkAction(action) {
      this.batchActionsMenu = false;
      this.batchActionsMenuMobile = false;

      // Extract IDs from selected items
      this.selectedItemIds = this.newItemsSelected.map((item) => item.id);
      this.action = action;
      this.showBulkActionDialog = true;
    },
    selectAllToggle(props) {
      // If props is true, select all items, otherwise clear selection
      if (props.value) {
        this.newItemsSelected = [...this.items];
      } else {
        this.newItemsSelected = [];
      }
    },
  },
};
</script>

<style scoped>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
}

.v-application .primary--text {
  color: #8b1a29 !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #8b1a29 !important;
}

.text-red {
  color: red;
}

.text-white {
  color: white;
}

/* Desktop Toolbar Styles */
.toolbar-container {
  padding: 8px 16px 16px 16px;
}

.search-field {
  max-width: 300px;
}

/* Bulk Actions Menu Styling */
.bulk-actions-menu {
  border-radius: 4px;
  overflow: hidden;
}

.bulk-action-item {
  transition: background-color 0.2s;
}

.bulk-action-item:hover {
  background-color: rgba(139, 26, 41, 0.05);
}

.v-list-item__icon {
  margin-right: 16px !important;
}

.v-list-item__title {
  font-weight: 500;
}

/* Ensure dividers look nice */
.v-divider {
  margin: 0 !important;
}

/* Animation for the dropdown */
.slide-y-transition-enter-active,
.slide-y-transition-leave-active {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Tab button styles */
.v-btn-toggle {
  background-color: #f5f5f5;
  border-radius: 4px;
  overflow: hidden;
}

.v-btn-toggle .v-btn {
  border-radius: 0;
  height: 36px;
  min-width: 80px;
}

.v-btn-toggle .v-btn.v-btn--active {
  background-color: #8b1a29 !important;
  color: white !important;
}

/* Mobile toolbar container */
.mobile-toolbar-container {
  padding: 12px 0;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}

.mobile-toolbar-row {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  width: 100%;
}

.mobile-toolbar-row:not(:last-child) {
  margin-bottom: 8px;
}

/* Compact buttons for mobile */
.mobile-compact-btn {
  height: 32px;
  min-width: 0;
  padding: 0 8px;
  font-size: 12px;
}

/* Mobile tab styles */
.mobile-tabs {
  min-width: 110px;
  height: 32px;
}

.mobile-tab-btn {
  font-size: 12px;
  height: 32px;
  padding: 0 8px;
  min-width: 55px;
}

.mobile-filter {
  max-width: 100px;
}

.mobile-search {
  flex-grow: 1;
}

/* Mobile card view styles */
.mobile-card {
  width: 100% !important;
  margin: 0 !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.mobile-description {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  word-break: break-word;
  text-align: center;
  padding: 8px 0;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.12);
  margin-bottom: 12px !important;
  line-height: 1.2;
}

.mobile-info-list {
  padding: 8px;
  background-color: #fafafa;
  border-radius: 4px;
}

.mobile-info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background-color: white;
  margin-bottom: 4px;
}

.mobile-info-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.price-info {
  padding: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.expiry-chip {
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 12px;
  white-space: nowrap;
}

.expired {
  background-color: #ffcdd2;
  color: #b71c1c;
}

.expiring-soon {
  background-color: #fff9c4;
  color: #f57f17;
}

.valid-expiry {
  background-color: #c8e6c9;
  color: #2e7d32;
}

.no-expiry {
  background-color: #e0e0e0;
  color: #616161;
}

/* Ensure mobile dropdown is visible */
.v-menu__content {
  z-index: 1000 !important;
}

::v-deep .v-data-table-header th:first-child {
  background: #8b1a29 !important;
}

::v-deep .v-data-table-header th:first-child .v-input--selection-controls {
  margin: 0;
  padding: 0;
}

::v-deep
  .v-data-table-header
  th:first-child
  .v-input--selection-controls
  .v-input__slot {
  margin: 0;
  padding: 0;
}

/* Fix for v-data-table on mobile */
.v-data-table--mobile > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 !important;
  width: 100%;
}

/* Make sure the table takes full width on mobile */
.v-data-table {
  width: 100% !important;
}

@media (max-width: 600px) {
  .v-data-table {
    font-size: 14px;
  }

  .v-toolbar {
    padding: 8px;
  }

  .font-weight-medium {
    font-weight: 500;
  }

  .v-container {
    padding: 0 !important;
  }

  .v-data-table__wrapper {
    width: 100%;
  }

  /* Ensure the mobile toolbar rows stack properly */
  .mobile-toolbar-row {
    flex-wrap: nowrap;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .v-data-table {
    font-size: 15px;
  }
}

@media (max-width: 400px) {
  .mobile-compact-btn {
    font-size: 11px;
    padding: 0 6px !important;
  }

  .mobile-filter {
    max-width: 80px;
  }
}

/* Add these specific fixes for mobile view */
@media (max-width: 600px) {
  /* Fix container padding */
  .v-container {
    padding: 0 !important;
    margin: 0 !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  /* Fix data table width */
  .v-data-table {
    width: 100vw !important;
    max-width: 100vw !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  /* Fix table wrapper */
  .v-data-table__wrapper {
    width: 100vw !important;
    max-width: 100vw !important;
    overflow-x: hidden !important;
  }

  /* Fix table rows */
  .v-data-table__wrapper > table {
    width: 100% !important;
  }

  /* Fix table cells */
  .v-data-table__wrapper > table > tbody > tr > td {
    width: 100vw !important;
    padding: 0 !important;
  }

  /* Fix mobile card */
  .mobile-card {
    width: 100vw !important;
    margin: 4px 0 !important;
    box-sizing: border-box !important;
    border-radius: 0 !important;
  }

  /* Remove any horizontal scrolling */
  body,
  html {
    overflow-x: hidden !important;
  }
}

.desktop-container {
  margin: 8px 8px 0 8px !important;
  padding-right: 0px !important;
  width: calc(100% - 16px) !important;
  max-width: calc(100% - 16px) !important;
}

.compact-toggle {
  margin-bottom: 2px !important;
  height: 40px !important;
}

.compact-toggle .v-btn {
  min-width: 70px !important;
  height: 40px !important;
  font-size: 13px !important;
}
</style>
