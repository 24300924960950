<template>
  <v-container>
    <v-toolbar flat v-if="!$vuetify.breakpoint.smAndDown">
      <template v-if="activeTable === 'new'">
        <v-btn
          :disabled="!selected.length"
          color="success"
          class="mr-2"
          @click="showConfirmationDialog(selected, 'accept')"
        >
          <v-icon left>mdi-check-circle</v-icon>
          Accept Selected ({{ selected.length }})
        </v-btn>
        <v-btn
          :disabled="!selected.length"
          color="error"
          class="mr-2"
          @click="showConfirmationDialog(selected, 'reject')"
        >
          <v-icon left>mdi-close</v-icon>
          Reject Selected ({{ selected.length }})
        </v-btn>
      </template>

      <v-btn
        v-if="showDownloadButton"
        @click="downloadCSV"
        class="primary mr-2"
      >
        <v-icon left>mdi-download</v-icon>
        Download BR CSV
      </v-btn>

      <v-spacer></v-spacer>

      <!-- Desktop Tab Toggle -->
      <v-btn-toggle v-model="activeTable" mandatory class="mr-4">
        <v-btn value="new">New</v-btn>
        <v-btn value="history" @click="getHistoryItems">History</v-btn>
      </v-btn-toggle>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class="w40"
        outlined
        dense
      >
      </v-text-field>
    </v-toolbar>

    <!-- Mobile Toolbar - Redesigned -->
    <div class="mobile-toolbar-container" v-else>
      <!-- New Tab Mobile Layout -->
      <template v-if="activeTable === 'new'">
        <!-- First Row: Accept, Reject, and Tab Toggle all in one row -->
        <div class="mobile-toolbar-row">
          <v-btn
            :disabled="!selected.length"
            color="success"
            class="mobile-compact-btn"
            @click="showConfirmationDialog(selected, 'accept')"
          >
            <v-icon small>mdi-check-circle</v-icon>
            <span class="ml-1">{{ selected.length }}</span>
          </v-btn>

          <v-btn
            :disabled="!selected.length"
            color="error"
            class="mobile-compact-btn ml-1"
            @click="showConfirmationDialog(selected, 'reject')"
          >
            <v-icon small>mdi-close</v-icon>
            <span class="ml-1">{{ selected.length }}</span>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn-toggle
            v-model="activeTable"
            mandatory
            class="mobile-tabs"
            dense
          >
            <v-btn value="new" class="mobile-tab-btn">New</v-btn>
            <v-btn
              value="history"
              class="mobile-tab-btn"
              @click="getHistoryItems"
              >History</v-btn
            >
          </v-btn-toggle>
        </div>

        <!-- Second Row: Search Bar Only -->
        <div class="mobile-toolbar-row">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mobile-search"
            outlined
            dense
          >
          </v-text-field>
        </div>
      </template>

      <!-- History Tab Mobile Layout -->
      <template v-else>
        <!-- First Row: Tab Toggle and Download Button -->
        <div class="mobile-toolbar-row">
          <v-btn-toggle
            v-model="activeTable"
            mandatory
            class="mobile-tabs"
            dense
          >
            <v-btn value="new" class="mobile-tab-btn">New</v-btn>
            <v-btn
              value="history"
              class="mobile-tab-btn"
              @click="getHistoryItems"
              >History</v-btn
            >
          </v-btn-toggle>

          <v-spacer></v-spacer>

          <v-btn
            v-if="showDownloadButton"
            @click="downloadCSV"
            class="primary mobile-compact-btn"
          >
            <v-icon small left>mdi-download</v-icon>
            CSV
          </v-btn>
        </div>

        <!-- Second Row: Search Bar Only -->
        <div class="mobile-toolbar-row">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mobile-search"
            outlined
            dense
          >
          </v-text-field>
        </div>
      </template>
    </div>

    <v-data-table
      v-if="activeTable === 'new'"
      v-model="selected"
      show-select
      :headers="newTableHeaders"
      :items="items"
      :search="search"
      item-key="id"
      class="elevation-1"
      :loading="loading"
      striped
      :height="$vuetify.breakpoint.smAndDown ? 'calc(100vh - 120px)' : '78vh'"
      fixed-header
      :footer-props="footerProps"
      :mobile-breakpoint="0"
    >
      <!-- Table slots remain unchanged -->
      <template v-slot:[`item.number`]="{ index }">
        <span>{{ index + 1 }}</span>
      </template>
      <template v-slot:[`item.upc`]="{ item }">
        <span class="text-align-center">{{ item.upc ? item.upc : "-" }}</span>
      </template>
      <template v-slot:[`item.vendor`]="{ item }">
        <span class="text-align-center">{{
          item.vendor.name ? item.vendor.name : "-"
        }}</span>
      </template>
      <template v-slot:[`item.old_price`]="{ item }">
        <span class="text-align-center">{{
          item.old_price ? item.old_price : "-"
        }}</span>
      </template>
      <template v-slot:[`item.new_price`]="{ item }">
        <span class="text-align-center">{{
          item.new_price ? item.new_price : "-"
        }}</span>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span>{{
          item.created_at ? formattedDate(item.created_at) : "-"
        }}</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <span
          ><v-icon
            class="text-green"
            @click="showConfirmationDialog(item, 'accept')"
            >mdi-check-circle</v-icon
          ></span
        >
        <span
          ><v-icon
            class="text-red ml-2"
            @click="showConfirmationDialog(item, 'reject')"
            >mdi-close</v-icon
          ></span
        >
        <span
          ><v-icon class="text-red ml-2" @click="deleteItem(item)"
            >mdi-delete</v-icon
          ></span
        >
      </template>
    </v-data-table>

    <v-data-table
      v-else
      :headers="historyTableHeaders"
      :items="historyItems"
      :search="search"
      item-key="unique_identifier"
      class="elevation-1"
      :loading="loading"
      striped
      :height="$vuetify.breakpoint.smAndDown ? 'calc(100vh - 120px)' : '78vh'"
      fixed-header
      :footer-props="footerProps"
      :mobile-breakpoint="0"
    >
      <!-- History table slots remain unchanged -->
      <template v-slot:[`item.number`]="{ index }">
        <span>{{ index + 1 }}</span>
      </template>
      <template v-slot:[`item.upc`]="{ item }">
        <span class="text-align-center">{{ item.upc ? item.upc : "-" }}</span>
      </template>
      <template v-slot:[`item.vendor`]="{ item }">
        <span class="text-align-center">{{
          item.vendor.name ? item.vendor.name : "-"
        }}</span>
      </template>
      <template v-slot:[`item.user`]="{ item }">
        <span class="text-align-center">{{
          item.user.name ? item.user.name : "-"
        }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          v-if="item.status == 'reject'"
          color="secondary"
          variant="flat"
          class="pill text-white"
        >
          Rejected
        </v-chip>
        <v-chip color="green" class="pill text-white" variant="flat" v-else>
          Accepted
        </v-chip>
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <span>{{
          item.updated_at ? formattedDate(item.updated_at) : "-"
        }}</span>
      </template>
      <template v-slot:[`item.old_price`]="{ item }">
        <span class="text-align-center">{{
          item.old_price ? item.old_price : "-"
        }}</span>
      </template>
      <template v-slot:[`item.new_price`]="{ item }">
        <span class="text-align-center">{{
          item.new_price ? item.new_price : "-"
        }}</span>
      </template>
    </v-data-table>

    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />
    <price-action-dialog
      v-if="showPriceActionDialog"
      v-model="showPriceActionDialog"
      :item="priceChangeItem"
      :action="priceChangeAction"
      @reload-listing="getPriceChangeItems"
      @show-alert="showAlertData"
      @close-dialog="showPriceActionDialog = false"
    />

    <delete-price-change-dialog
      v-if="showDeleteItemDialog"
      v-model="showDeleteItemDialog"
      :item="priceChangeItem"
      @close-dialog="showDeleteItemDialog = false"
      @reload-vendors="getPriceChangeItems"
      @show-alert="showAlertData"
    />
  </v-container>
</template>

<script>
// Script section remains unchanged
import AlertComponent from "@/components/common/AlertComponent";
import PriceActionDialog from "./PriceActionDialog";
import DeletePriceChangeDialog from "./DeletePriceChangeDialog";

export default {
  name: "vendor-listing",
  data() {
    return {
      selected: [],
      selectAll: false,
      search: "",
      loading: false,
      activeTable: "new", // Toggle between "new" and "history"
      items: [],
      historyItems: [],
      showAlert: false,
      showPriceActionDialog: false,
      alertData: {},
      priceChangeItem: {},
      showDownloadButton: false,
      showDeleteItemDialog: false,
      priceChangeAction: "",
      newTableHeaders: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        { text: "UPC", value: "upc", class: "table-header" },
        { text: "Vendor", value: "vendor", class: "table-header" },
        {
          text: "Old Per Unit Price",
          value: "old_price",
          class: "table-header",
        },
        {
          text: "New Per Unit Price",
          value: "new_price",
          class: "table-header",
        },
        { text: "Added At", value: "created_at", class: "table-header" },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
          width: "10%",
        },
      ],
      historyTableHeaders: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        { text: "UPC", value: "upc", class: "table-header" },
        { text: "Vendor", value: "vendor", class: "table-header" },
        {
          text: "Old Per Unit Price",
          value: "old_price",
          class: "table-header",
        },
        {
          text: "New Per Unit Price",
          value: "new_price",
          class: "table-header",
        },
        { text: "Status", value: "status", class: "table-header" },
        { text: "Performed By", value: "user", class: "table-header" },
        { text: "Updated At", value: "updated_at", class: "table-header" },
      ],
      footerProps: {
        "items-per-page-options": [
          20,
          50,
          100,
          500,
          { text: "All", value: -1 },
        ],
      },
    };
  },
  components: {
    AlertComponent,
    PriceActionDialog,
    DeletePriceChangeDialog,
  },
  mounted() {
    document.title = "Razcofoods | Price Change";
    this.getPriceChangeItems();
  },
  watch: {
    selectAll() {
      this.selectAllItems();
    },
    activeTable(newValue) {
      if (newValue === "history") {
        this.getHistoryItems();
      } else {
        this.getPriceChangeItems();
      }
    },
  },
  methods: {
    async getPriceChangeItems() {
      this.selected = [];
      this.showDownloadButton = false;
      this.loading = true;
      let items = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/price-change"
      );
      if (items.data && items.data.status) {
        this.items = items.data.data;
      }
      this.loading = false;
    },
    async getHistoryItems() {
      this.showDownloadButton = true;
      this.loading = true;
      let items = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/price-change-history"
      );
      if (items.data && items.data.status) {
        this.historyItems = items.data.data;
      }
      this.loading = false;
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    showAlertData(alertData) {
      this.alertData = alertData;
      this.showAlert = true;
    },
    showConfirmationDialog(item, action) {
      this.priceChangeAction = action;

      if (!Array.isArray(item)) {
        if (typeof item === "object" && item !== null) {
          this.priceChangeItem = [item];
        } else {
          this.priceChangeItem = [{ value: item }];
        }
      } else {
        this.priceChangeItem = item.map((element) => {
          if (typeof element === "object" && element !== null) {
            return element;
          } else {
            return { value: element };
          }
        });
      }

      this.showPriceActionDialog = true;
    },
    downloadCSV() {
      const csvContent = this.generateCSV();
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "history_br_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    generateCSV() {
      // Define the CSV header
      const header = ["UPC", "Old Price", "New Price"].join(",");

      // Filter and map through historyItems to extract the relevant fields
      const rows = this.historyItems
        .filter((item) => item.status === "accept") // Include only items with status = "accept"
        .map((item) => {
          const upc = item.upc || "-";
          const oldPrice = item.old_price || "-";
          const newPrice = item.new_price || "-";
          return [upc, oldPrice, newPrice].join(",");
        });

      // Join the header and rows to create the final CSV content
      return [header, ...rows].join("\n");
    },
    selectAllItems() {
      if (this.selectAll) {
        this.selected = [...this.items];
      } else {
        this.selected = [];
      }
    },
    deleteItem(item) {
      this.showDeleteItemDialog = true;
      this.priceChangeItem = item;
    },
  },
};
</script>

<style scoped>
.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}

.v-btn--active {
  background-color: #8b1a29 !important;
  color: #fff !important;
}

/* Tab button styles */
.v-btn-toggle {
  background-color: #f5f5f5;
  border-radius: 4px;
  overflow: hidden;
}

.v-btn-toggle .v-btn {
  border-radius: 0;
  height: 36px;
  min-width: 80px;
}

.v-btn-toggle .v-btn.v-btn--active {
  background-color: #8b1a29 !important;
  color: white !important;
}

::v-deep .v-data-table-header th:first-child {
  background: #8b1a29 !important;
}

::v-deep .v-data-table-header th:first-child .v-input--selection-controls {
  margin: 0;
  padding: 0;
}

::v-deep
  .v-data-table-header
  th:first-child
  .v-input--selection-controls
  .v-input__slot {
  margin: 0;
  padding: 0;
}

/* Mobile toolbar container */
.mobile-toolbar-container {
  padding: 12px 0;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}

.mobile-toolbar-row {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  width: 100%;
}

.mobile-toolbar-row:not(:last-child) {
  margin-bottom: 8px;
}

.mobile-search {
  width: 100%;
}

/* Compact buttons for mobile */
.mobile-compact-btn {
  height: 32px;
  min-width: 0;
  padding: 0 8px;
  font-size: 12px;
}

/* Mobile tab styles */
.mobile-tabs {
  min-width: 110px;
  height: 32px;
}

.mobile-tab-btn {
  font-size: 12px;
  height: 32px;
  padding: 0 8px;
  min-width: 55px;
}

/* Responsive styles */
@media (max-width: 600px) {
  .v-data-table {
    font-size: 14px;
  }

  .table-header {
    font-size: 12px !important;
  }

  /* Make check/reject buttons more touch-friendly */
  .text-green,
  .text-red {
    font-size: 20px;
    margin: 0 8px;
  }

  /* Ensure the mobile toolbar rows stack properly */
  .mobile-toolbar-row {
    flex-wrap: nowrap;
  }

  /* Ensure search bar takes full width */
  .mobile-search {
    width: 100%;
  }
}

/* Table responsiveness for horizontal scrolling */
@media (max-width: 960px) {
  .v-data-table {
    overflow-x: auto;
  }

  /* Ensure buttons in rows are properly sized */
  td .v-icon {
    font-size: 20px;
  }
}
</style>
