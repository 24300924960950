<template>
  <!-- Edit Dialog Componenet -->
  <v-container>
    <v-dialog v-model="showEditInventoryDialog" max-width="700" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-3">
          Update New Item
        </v-card-title>

        <v-card-text class="mt-3">
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.upc"
                    label="UPC"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>

                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.description"
                    label="Description"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.pack"
                    label="Pack"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.size"
                    label="Size"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>

                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.retail"
                    label="Retail"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.base_unit"
                    label="Base/Unit"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>

                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.net_cost"
                    label="Net Cost"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>

                <v-col cols="12" md="6" class="mb-3">
                  <v-select
                    v-model="localInventoryItem.department_id"
                    :items="departments"
                    item-text="name"
                    item-value="id"
                    label="Select Department"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>

                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.upc_case"
                    label="UPC Case"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>

                
                <v-col cols="12" md="6" class="mb-3">
                  <v-text-field
                    v-model="localInventoryItem.base_case"
                    label="Base/Case"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#8b1a29"
            @click="updateInventory(localInventoryItem)"
            style="color: white"
            >Update</v-btn
          >
          <v-btn text @click="$emit('close-dialog')">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "edit-inventory-dialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    inventoryItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showEditInventoryDialog: this.value,
      localInventoryItem: { ...this.inventoryItem },
      departments: [],
      categories: [],
    };
  },
  mounted() {
    this.getDepartments();
    this.getCategories();
  },
  methods: {
    async updateInventory(item) {
      let alertData = {};
      let res = await this.$axios.put(
        process.env.VUE_APP_API_URL + "/new-items/" + item.id,
        this.localInventoryItem
      );

      if (res.data.status) {
        alertData.alertText = res.data.message;
        alertData.alertType = "success";
        this.$emit("reload-inventory");
      } else {
        alertData.alertText = res.data.message;
        alertData.alertType = "error";
      }

      this.$emit("show-alert", alertData);
      this.$emit("close-dialog");
    },
    getDepartments() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/department")
        .then((res) => {
          this.departments = res.data.data;
        });
    },
    getCategories() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/category").then((res) => {
        this.categories = res.data.data;
      });
    },
  },
  watch: {
    value(val) {
      this.showEditInventoryDialog = val;
    },
    showEditInventoryDialog(val) {
      this.$emit("input", val);
      if (!val) {
        this.$emit("close-dialog");
      }
    },
  },
};
</script>
