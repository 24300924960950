<template>
  <v-container fluid class="pa-0 pa-sm-2">
    <v-data-table
      :headers="visibleHeaders"
      :items="items"
      :search="search"
      item-key="upc"
      class="elevation-1"
      :loading="loading"
      striped
      :height="$vuetify.breakpoint.smAndDown ? 'calc(100vh - 120px)' : '78vh'"
      fixed-header
      :footer-props="footerProps"
      theme="dark"
      :mobile-breakpoint="0"
      :server-items-length="totalItems"
      :items-per-page="itemsPerPage"
      @update:options="updateTableOptions"
    >
      <template v-slot:top>
        <!-- Desktop Toolbar -->
        <v-toolbar
          flat
          class="toolbar-container"
          v-if="!$vuetify.breakpoint.smAndDown"
        >
          <div class="action-buttons">
            <v-btn
              color="#8b1a29"
              class="ml-2 text-white"
              @click="showAddInventoryDialog = true"
            >
              <v-icon left>mdi-plus</v-icon>
              Add Item
            </v-btn>

            <file-upload
              class="upload-btn"
              @start-loader="loading = true"
              @stop-loader="loading = false"
              @reload-inventory="getInventory"
              @show-alert="showAlertData"
            ></file-upload>

            <!-- Column Selection Button -->
            <v-btn
              color="#8b1a29"
              class="ml-2 text-white"
              @click="showColumnSelectionDialog = true"
            >
              <v-icon left>mdi-table-column-width</v-icon>
              Columns
            </v-btn>
          </div>

          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="search-field"
            outlined
            dense
            @input="debouncedSearch"
          ></v-text-field>
        </v-toolbar>

        <!-- Mobile Toolbar -->
        <v-toolbar flat class="mobile-toolbar" v-else>
          <div class="mobile-actions">
            <!-- First row: Action buttons -->
            <div class="mobile-buttons-row">
              <v-btn
                color="#8b1a29"
                class="text-white mobile-btn"
                @click="showAddInventoryDialog = true"
              >
                <v-icon left>mdi-plus</v-icon>
                Add Item
              </v-btn>

              <file-upload
                class="mobile-upload-btn"
                @start-loader="loading = true"
                @stop-loader="loading = false"
                @reload-inventory="getInventory"
                @show-alert="showAlertData"
              ></file-upload>

              <!-- Mobile Column Selection Button -->
              <v-btn
                color="#8b1a29"
                class="text-white mobile-column-btn"
                @click="showColumnSelectionDialog = true"
              >
                <!-- <v-icon left>mdi-table-column-width</v-icon> -->
                Columns
              </v-btn>
            </div>

            <!-- Second row: Search field -->
            <div class="mobile-search-row">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="mobile-search"
                outlined
                dense
                @input="debouncedSearch"
              ></v-text-field>
            </div>
          </div>
        </v-toolbar>
      </template>

      <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.smAndDown">
        <tr :class="getRowClass(item)">
          <td>
            <v-card flat class="pa-3">
              <div class="d-flex justify-space-between align-center">
                <div>
                  <div class="font-weight-bold">UPC: {{ item.upc || "-" }}</div>
                  <div>Description: {{ item.description || "-" }}</div>
                  <div>Brand: {{ item.brand || "-" }}</div>
                  <div>Retail: {{ item.retail || "-" }}</div>
                </div>
                <div>
                  <v-btn icon small @click="viewInventoryDetail(item)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn icon small @click="editInventory(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon small @click="deleteInventory(item)" color="red">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </td>
        </tr>
      </template>

      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="item.upc"
            :class="getRowClass(item)"
          >
            <!-- Only render columns that are in userSelectedColumns -->
            <td v-if="isColumnVisible('number')">{{ index + 1 }}</td>
            <td v-if="isColumnVisible('upc')">
              <span class="text-align-center no-wrap">{{
                item.upc ? item.upc : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('short_code')">
              <span class="text-align-center no-wrap">{{
                item.short_code ? item.short_code : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('vendor')">
              <span class="text-align-center no-wrap">{{
                item.vendor ? item.vendor : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('description')">
              <span class="text-align-center no-wrap">{{
                item.description ? item.description : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('brand')">
              <span class="text-align-center no-wrap">{{
                item.brand ? item.brand : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('wh_item')">
              <span class="text-align-center no-wrap">{{
                item.wh_item ? item.wh_item : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('pack')">
              <span class="text-align-center no-wrap">{{
                item.pack ? item.pack : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('size')">
              <span class="text-align-center no-wrap">{{
                item.size ? item.size : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('quantity')">
              <span class="text-align-center no-wrap">{{
                item.quantity ? item.quantity : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('retail')">
              <span class="text-align-center no-wrap">{{
                item.retail ? item.retail : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('base_unit')">
              <span class="text-align-center no-wrap">{{
                item.base_unit ? item.base_unit : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('gross_margin')">
              <span class="text-align-center no-wrap">{{
                item.gross_margin ? item.gross_margin : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('stock')">
              <span class="text-align-center no-wrap">{{
                item.stock ? item.stock : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('effective_qty')">
              <span class="text-align-center no-wrap">{{
                item.effective_qty ? item.effective_qty : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('effective_price')">
              <span class="text-align-center no-wrap">{{
                item.effective_price ? item.effective_price : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('from_date')">
              <span class="text-align-center no-wrap">{{
                item.from_date ? item.from_date : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('to_date')">
              <span class="text-align-center no-wrap">{{
                item.to_date ? item.to_date : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('net_cost')">
              <span class="text-align-center no-wrap">{{
                item.net_cost ? item.net_cost : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('dept_description')">
              <span class="text-align-center no-wrap">{{
                item.department?.name ? item.department?.name : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('category_sub_description')">
              <span class="text-align-center no-wrap">{{
                item.category?.name ? item.category?.name : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('enhanced_dept')">
              <span class="text-align-center no-wrap">{{
                item.enhanced_dept ? item.enhanced_dept : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('scan_dept')">
              <span class="text-align-center no-wrap">{{
                item.scan_dept ? item.scan_dept : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('upc_case')">
              <span class="text-align-center no-wrap">{{
                item.upc_case ? item.upc_case : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('retail_prior')">
              <span class="text-align-center no-wrap">{{
                item.retail_prior ? item.retail_prior : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('tpr_priority')">
              <span class="text-align-center no-wrap">{{
                item.tpr_priority ? item.tpr_priority : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('target_margin')">
              <span class="text-align-center no-wrap">{{
                item.target_margin ? item.target_margin : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('loq')">
              <span class="text-align-center no-wrap">{{
                item.loq ? item.loq : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('vendor_item')">
              <span class="text-align-center no-wrap">{{
                item.vendor_item ? item.vendor_item : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('base_case')">
              <span class="text-align-center no-wrap">{{
                item.base_case ? item.base_case : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('base_margin')">
              <span class="text-align-center no-wrap">{{
                item.base_margin ? item.base_margin : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('excise_tax')">
              <span class="text-align-center no-wrap">{{
                item.excise_tax ? item.excise_tax : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('tpr_qty')">
              <span class="text-align-center no-wrap">{{
                item.tpr_qty ? item.tpr_qty : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('tpr')">
              <span class="text-align-center no-wrap">{{
                item.tpr ? item.tpr : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('ap_qty')">
              <span class="text-align-center no-wrap">{{
                item.adp_qty ? item.adp_qty : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('adp')">
              <span class="text-align-center no-wrap">{{
                item.adp ? item.adp : "-"
              }}</span>
            </td>
            <td v-if="isColumnVisible('created_at')">
              <span class="text-align-center no-wrap">{{
                formattedDate(item.created_at)
              }}</span>
            </td>
            <td v-if="isColumnVisible('action')">
              <span class="no-wrap">
                <span>
                  <v-icon @click="viewInventoryDetail(item)">mdi-eye</v-icon>
                </span>
                <span class="ml-1">
                  <v-icon @click="editInventory(item)">mdi-pencil</v-icon>
                </span>
                <span>
                  <v-icon class="text-red" @click="deleteInventory(item)"
                    >mdi-delete</v-icon
                  >
                </span>
                <!-- Discount icon - only show if item has a discount -->
                <span v-if="hasDiscount(item)" class="ml-1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="#4CAF50" v-bind="attrs" v-on="on">
                        mdi-sale
                      </v-icon>
                    </template>
                    <span>This item has a discount</span>
                  </v-tooltip>
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <add-inventory-dialog
      v-if="showAddInventoryDialog"
      @close-dialog="showAddInventoryDialog = false"
      @reload-inventory="getInventory"
      @show-alert="showAlertData"
    />

    <view-inventory-dialog
      v-if="showViewInventoryDialog"
      v-model="showViewInventoryDialog"
      :inventoryItem="selectedInventory"
      @close-dialog="showViewInventoryDialog = false"
    />

    <edit-inventory-dialog
      v-if="showEditInventoryDialog"
      v-model="showEditInventoryDialog"
      :inventoryItem="selectedInventory"
      @close-dialog="showEditInventoryDialog = false"
      @reload-inventory="getInventory"
      @show-alert="showAlertData"
    />

    <delete-inventory-dialog
      v-if="showDeleteInventoryDialog"
      v-model="showDeleteInventoryDialog"
      :inventoryItem="selectedInventory"
      @close-dialog="showDeleteInventoryDialog = false"
      @reload-inventory="getInventory"
      @show-alert="showAlertData"
    />

    <alert-component
      v-if="showAlert"
      v-model="showAlert"
      :data="alertData"
      @close-alert="showAlert = false"
    />

    <column-selection-dialog
      v-model="showColumnSelectionDialog"
      :headers="headers"
      :userSelectedColumns="userSelectedColumns"
      @save-columns="saveColumnPreferences"
    />
  </v-container>
</template>

<script>
/* eslint-disable */
import FileUpload from "@/components/FileUpload/FileUpload";
import AddInventoryDialog from "@/components/Inventory/AddInventoryDialog";
import ViewInventoryDialog from "@/components/Inventory/ViewInventoryDialog";
import EditInventoryDialog from "@/components/Inventory/EditInventoryDialog";
import DeleteInventoryDialog from "@/components/Inventory/DeleteInventoryDialog";
import AlertComponent from "@/components/common/AlertComponent";
import ColumnSelectionDialog from "@/components/Inventory/ColumnSelectionComponent";
import _ from "lodash";

export default {
  name: "listing-data",
  data() {
    return {
      search: "",
      loading: false,
      itemsPerPage: 20,
      counter: 0,
      showAlert: false,
      alertData: {},
      showColumnSelectionDialog: false,
      userSelectedColumns: [],
      headers: [
        {
          text: "Sr.#",
          value: "number",
          sortable: false,
          class: "table-header",
        },
        { text: "UPC", value: "upc", class: "table-header", sortable: true },
        { text: "Short Code", value: "short_code", class: "table-header", sortable: true },
        {
          text: "Vendor",
          value: "vendor",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Description",
          value: "description",
          class: "table-header",
          width: "15%",
          sortable: true,
        },
        {
          text: "Brand",
          value: "brand",
          class: "table-header",
          width: "8%",
          sortable: true,
        },
        {
          text: "WH Item",
          value: "wh_item",
          class: "table-header",
          sortable: true,
        },
        { text: "Pack", value: "pack", class: "table-header", sortable: true },
        { text: "Size", value: "size", class: "table-header", sortable: true },
        {
          text: "Quantity",
          value: "quantity",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Retail",
          value: "retail",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Base Unit",
          value: "base_unit",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Gross Margin%",
          value: "gross_margin",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Stock",
          value: "stock",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Effective Qty",
          value: "effective_qty",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Effective Price",
          value: "effective_price",
          class: "table-header",
          sortable: true,
        },
        {
          text: "From Date",
          value: "from_date",
          class: "table-header",
          width: "10%",
          sortable: true,
        },
        {
          text: "To Date",
          value: "to_date",
          class: "table-header",
          width: "8%",
          sortable: true,
        },
        {
          text: "Net Cost",
          value: "net_cost",
          class: "table-header",
          sortable: true,
        },
        {
          text: "Dept Description",
          value: "dept_description",
          class: "table-header",
          width: "8%",
          sortable: true,
        },
        {
          text: "Category Sub Description",
          value: "category_sub_description",
          class: "table-header",
          width: "8%",
          sortable: true,
        },
        {
          text: "Enhanced Dept",
          value: "enhanced_dept",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Scan Dept",
          value: "scan_dept",
          class: "table-header",
          width: "8%",
        },
        {
          text: "UPC Case",
          value: "upc_case",
          class: "table-header",
          width: "8%",
          sortable: true,
        },
        {
          text: "Retail Prior",
          value: "retail_prior",
          class: "table-header",
          width: "8%",
        },
        {
          text: "TPR Priority",
          value: "tpr_priority",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Target Margin%",
          value: "target_margin",
          class: "table-header",
          width: "8%",
        },
        {
          text: "LOQ",
          value: "loq",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Vendor Item",
          value: "vendor_item",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Base Case",
          value: "base_case",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Base Margin%",
          value: "base_margin",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Excise Tax",
          value: "excise_tax",
          class: "table-header",
          width: "8%",
        },
        {
          text: "TPR Qty",
          value: "tpr_qty",
          class: "table-header",
          width: "8%",
        },
        {
          text: "TPR",
          value: "tpr",
          class: "table-header",
          width: "8%",
        },
        {
          text: "ADP Qty",
          value: "ap_qty",
          class: "table-header",
          width: "8%",
        },
        {
          text: "ADP",
          value: "adp",
          class: "table-header",
          width: "8%",
        },
        {
          text: "Added At",
          value: "created_at",
          class: "table-header",
        },
        {
          text: "Actions",
          value: "action",
          class: "table-header",
          width: "10%",
        },
      ],
      footerProps: {
        "items-per-page-options": [20, 50, 100, 500],
      },
      items: [],
      showAddInventoryDialog: false,
      showViewInventoryDialog: false,
      showEditInventoryDialog: false,
      showDeleteInventoryDialog: false,
      selectedInventory: {},
      currentPage: 1,
      totalItems: 0,
      sortBy: "created_at",
      sortDesc: true,
    };
  },
  components: {
    FileUpload,
    AddInventoryDialog,
    ViewInventoryDialog,
    EditInventoryDialog,
    AlertComponent,
    DeleteInventoryDialog,
    ColumnSelectionDialog,
  },
  mounted() {
    document.title = "Razcofoods | Inventory";
    this.sortBy = "created_at";
    this.sortDesc = true;
    this.getUserColumnPreferences();
    this.getInventory();
  },
  computed: {
    getResponsiveHeaders() {
      // if (this.$vuetify.breakpoint.smAndDown) {
      //   return [{
      //     text: 'Item Details',
      //     value: 'upc',
      //     sortable: false,
      //     class: 'table-header'
      //   }];
      // }
      return this.headers;
    },
    visibleHeaders() {
      if (this.userSelectedColumns && this.userSelectedColumns.length > 0) {
        return this.headers.filter((header) =>
          this.userSelectedColumns.includes(header.value)
        );
      }
      return this.headers;
    },
  },
  methods: {
    debouncedSearch: _.debounce(function () {
      this.getInventory();
    }, 500),
    async getInventory() {
      this.loading = true;
      await this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/inventories?page=" +
            this.currentPage +
            "&per_page=" +
            this.itemsPerPage +
            "&search=" +
            this.search +
            "&sort_by=" +
            this.sortBy +
            "&sort_desc=" +
            this.sortDesc
        )

        .then((res) => {
          if (res.data && res.data.status) {
            this.items = res.data.data.data;
            this.totalItems = res.data.data.total;
          } else {
            this.items = res.data.data;
            this.totalItems = 0;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;
    },
    getRowClass(item) {
      if (item.retail <= 0) {
        return "zero-retail";
      }
      return "";
    },
    updateTableOptions({ page, itemsPerPage, sortBy, sortDesc }) {
      if (
        this.currentPage !== page ||
        this.itemsPerPage !== itemsPerPage ||
        this.sortBy !== sortBy ||
        this.sortDesc !== sortDesc
      ) {
        this.currentPage = page;
        this.itemsPerPage = itemsPerPage;
        this.sortBy = sortBy;
        this.sortDesc = sortDesc;
        this.getInventory();
      }
    },
    hasDiscount(item) {
      // Check if discount property exists
      if (!item.discount) return false;

      // Check if discounts.item_expiration exists
      if (!item.discount.item_expiration) return false;

      // Check if item_expiration.action exists and equals "accept"
      return item.discount.item_expiration.action === "accept";
    },
    formattedDate(date) {
      let dateToFormat = new Date(date);
      return dateToFormat.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    showAlertData(alertData) {
      this.alertData = alertData;
      this.showAlert = true;
    },
    viewInventoryDetail(item) {
      this.selectedInventory = item;
      this.showViewInventoryDialog = true;
    },
    editInventory(item) {
      this.selectedInventory = item;
      this.showEditInventoryDialog = true;
    },
    deleteInventory(item) {
      this.selectedInventory = item;
      this.showDeleteInventoryDialog = true;
    },
    async getUserColumnPreferences() {
      this.loading = true;
      try {
        const response = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/user/column-preferences/inventory`
        );

        if (response.data && response.data.status && response.data.data) {
          this.userSelectedColumns = response.data.data.columns || [];
        } else {
          // Default to all columns if no preferences are saved
          this.userSelectedColumns = this.headers.map((header) => header.value);
        }
      } catch (error) {
        console.error("Error fetching column preferences:", error);
        // Default to all columns if there's an error
        this.userSelectedColumns = this.headers.map((header) => header.value);
      }
      this.loading = false;
    },
    isColumnVisible(columnValue) {
      // If no columns are selected or the array is empty, show all columns
      if (!this.userSelectedColumns || this.userSelectedColumns.length === 0) {
        return true;
      }
      // Otherwise, check if the column is in the selected columns
      return this.userSelectedColumns.includes(columnValue);
    },
    async saveColumnPreferences(selectedColumns) {
      this.loading = true;
      try {
        const response = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/user/column-preferences/inventory`,
          {
            columns: selectedColumns,
          }
        );

        if (response.data && response.data.status) {
          this.userSelectedColumns = selectedColumns;
          this.showAlertData({
            alertType: "success",
            alertText: "Column preferences saved successfully!",
          });
        } else {
          this.showAlertData({
            alertType: "error",
            alertText: "Failed to save column preferences.",
          });
        }
      } catch (error) {
        console.error("Error saving column preferences:", error);
        this.showAlertData({
          alertType: "error",
          alertText: "An error occurred while saving column preferences.",
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.action-buttons {
  display: flex;
  gap: 8px;
  align-items: center;
}

.action-buttons-mobile {
  width: 100%;
  justify-content: space-between;
}

.action-btn {
  white-space: nowrap;
}

.search-field {
  max-width: 300px;
}

.search-field-mobile {
  width: 100%;
  max-width: none;
}

@media (max-width: 600px) {
  .action-buttons-mobile {
    width: 100%;
    justify-content: space-between;
  }
}
.w-100 {
  width: 100%;
}

.search-field {
  max-width: 300px;
}

@media (max-width: 600px) {
  .v-data-table {
    font-size: 14px;
  }

  .v-toolbar {
    padding: 8px;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .v-data-table {
    font-size: 15px;
  }
}

.v-data-table-header {
  font-weight: bold;
}

.w40 {
  max-width: 220px;
}

.text-align-center {
  text-align: center !important;
}

.margin-left-3 {
  margin-left: -3px;
}

.padding-top-20 {
  padding-top: 20px;
}

.table-header {
  background: #8b1a29 !important;
  color: #fff !important;
  text-wrap: nowrap !important;
  white-space: nowrap !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important;
  text-wrap: nowrap !important;
}

.v-application .primary--text {
  color: #8b1a29 !important;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #8b1a29 !important;
}

.text-red {
  color: red;
}

.text-white {
  color: white;
}

.no-wrap {
  white-space: nowrap;
}

.text-red {
  color: red !important;
}

.zero-retail {
  background-color: #95081b;
  color: white;
}

.zero-retail:hover {
  background-color: #bb0d24 !important;
}

/* Desktop Toolbar Styles */
.toolbar-container {
  padding: 8px 16px;
}

.action-buttons {
  display: flex;
  gap: 8px;
  align-items: center;
}

.search-field {
  max-width: 300px;
}

/* Mobile Toolbar Styles */
.mobile-toolbar {
  padding: 32px 0px !important;
  height: auto !important;
  display: flex;
  flex-direction: column;
}

.mobile-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mobile-buttons-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.mobile-search-row {
  width: 100%;
}

.mobile-btn {
  height: 40px;
  flex: 1;
  white-space: nowrap;
  font-size: 12px;
  padding: 2 8px !important;
}

.mobile-upload-btn {
  height: 40px;
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-column-btn {
  height: 40px;
  flex: 1;
  white-space: nowrap;
  font-size: 12px;
  padding: 0 8px !important;
}

.mobile-search {
  width: 100%;
  margin-top: 0;
  position: relative;
}

/* Fix for file upload button in mobile view */
.mobile-upload-btn :deep(.v-btn) {
  height: 40px !important;
  width: 100% !important;
  white-space: nowrap;
  font-size: 12px;
  padding: 0 8px !important;
  background-color: #8b1a29 !important;
  color: white !important;
}

/* Ensure consistent spacing */
.v-toolbar__content {
  padding: 0 !important;
}
</style>
